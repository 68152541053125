import BaseMixin from './base.mixin'

const mixin = {
  mixins: [BaseMixin],
  data() {
    return {
      categorySvg: '' // Armazenar o SVG carregado
    }
  },
  methods: {
    // Carregar o SVG baseado no linkClientId
    async loadCategorySvg(linkClientId) {
      try {
        const svgModule = await import(`../../assets/svg/category/${linkClientId}.svg?raw`)
        this.categorySvg = `<div class="physician-header-category-icon">${svgModule.default}</div>`
      } catch (error) {
        const defaultSvgModule = await import(`../../assets/svg/category/default.svg?raw`)
        this.categorySvg = `<div class="physician-header-category-icon">${defaultSvgModule.default}</div>`
      }
    }
  },
  watch: {
    'exam.linkCLientId': {
      handler(newVal) {
        if (newVal) {
          this.loadCategorySvg(newVal) // Carrega o SVG sempre que o linkClientId mudar
        }
      },
      immediate: true // Executa a função quando o componente for montado
    }
  }
}

export default mixin
