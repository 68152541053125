import DateMixin from '@/shared/mixins/date.mixin'
import RoleMixin from '@/shared/mixins/role.mixin'
import AuthMixin from '@/shared/mixins/auth.mixin'

export default {
  name: 'pendency-alert',
  mixin: [
    DateMixin,
    RoleMixin,
    AuthMixin
  ],
  props: {
    qtdPendency: {
      required: true
    }
  },
  data () {
    return {
      isAlertVisible: true
    }
  },
  methods: {
    openPendency () {
      localStorage.setItem('pendencyAlertDismissed', 'true')
      this.isAlertVisible = false
      this.$emit('hideAlert')
    },
    pendencyMessage () {
      const verify = this.qtdPendency >= 2 ? 2 : 1
      return this.$i18n.tc('variable.you_have_pendencies_to_solve', verify, { amount: this.qtdPendency })
    }
  },
  created () {
    if (localStorage.getItem('pendencyAlertDismissed') === 'true') {
      this.isAlertVisible = false
      this.$emit('hideAlert')
    }
  }
}
