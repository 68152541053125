// eslint-disable-next-line
import { store } from '@/store/store'
import BaseMixin from './base.mixin'

const mixin = {
  mixins: [
    BaseMixin
  ],
  methods: {
    getSpecialties () {
      return new Promise(async (resolve) => {
        const url = '/core/ExamSource/Speciality'
        if (!store.state.specialties || !store.state.specialties.length) {
          const res = await this.requestGet(url)
          const specialties = res.data
          store.dispatch('setSpecialties', specialties)
          return resolve(specialties)
        }

        return resolve(store.state.specialties)
      })
    }
  }
}

export default mixin
