import BaseMixin from './base.mixin'

const mixin = {
  mixins: [
    BaseMixin
  ],
  methods: {
    async getBillingList () {
      const url = '/core/Billing/List'
      const result = await this.getClient().get(url, {useSelectedSource: true}, true)
      return result.data
    },
    async getBillingStatement (reference) {
      const url = `/core/Billing/Statement?reference=${reference}`
      const result = await this.getClient().get(url, {useSelectedSource: true}, true)
      return result.data
    }
  }
}

export default mixin
