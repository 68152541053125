import Icon from '@/assets/svg/status/laudo_liberado.svg?raw'

export default {
  props: {
    details: {
      type: Object
    }
  },
  data () {
    return {
      icon: Icon
    }
  },
  methods: {
    convertDate (date) {
      const currentLanguage = this.$i18n.locale
      return currentLanguage === 'en-US' ? new Date(date).toLocaleString('en-US') : new Date(date).toLocaleString('pt-BR').slice(0, 10)
    }
  }
}
