import pt from './assets/i18n/pt.json'
import en from './assets/i18n/en.json'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import moment from 'moment/min/moment-with-locales'
import 'moment/locale/pt'

Vue.use(VueI18n)

const language = localStorage.getItem('lang') || navigator.language

// Configurando a instância Moment na raiz da aplicação
// moment.tz.setDefault('America/Sao_Paulo')
const languageAbbr = language.split('-')[0];
moment.locale(languageAbbr)

Vue.prototype.$moment = moment

export default new VueI18n({
  locale: language,
  fallbackLocale: 'en-US',
  messages: {
    'pt-BR': pt,
    'en-US': en
  },
  silentTranslationWarn: true
})
