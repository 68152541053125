import BaseMixin from './base.mixin'

const mixin = {
  mixins: [
    BaseMixin
  ],
  methods: {
    addExamObservation (observation) {
      return this.requestPost('/core/ExamSource/Exams/Observation', observation)
    }
  }
}

export default mixin
