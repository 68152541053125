import BaseMixin from './base.mixin'

const mixin = {
  mixins: [
    BaseMixin
  ],
  methods: {
    setUrgent (examId) {
      return this.requestPut('/core/ExamSource/Exams/SetUrgentExam', { examId })
    }
  }
}

export default mixin
