import { store } from '@/store/store'
import BaseMixin from './base.mixin'

const mixin = {
  mixins: [
    BaseMixin
  ],
  methods: {
    getRoles () {
      return new Promise((resolve) => {
        if (!store.state.roles || !store.state.roles.length) {
          const roles = [
            {
              'name': 'admin',
              'label': this.$i18n.t('user_role.administrator')
            },
            {
              'name': 'adminSource',
              'label': this.$i18n.t('user_role.source_administrator')
            },
            {
              'name': 'physician',
              'label': this.$i18n.t('user_role.physician')
            },
            {
              'name': 'source',
              'label': this.$i18n.t('user_role.operational')
            },
            {
              'name': 'adminPhysician',
              'label': this.$i18n.t('user_role.physician_administrator')
            }
          ]

          store.dispatch('setRoles', roles)
          return resolve(roles)
        }

        return resolve(store.state.roles)
      })
    }
  }
}

export default mixin
