import RepetirExame from '@/components/medico/repetir-exame/index.vue'
import DateMixin from '@/shared/mixins/date.mixin'
import RoleMixin from '@/shared/mixins/role.mixin'
import AuthMixin from '@/shared/mixins/auth.mixin'
import MedicalReportMixin from '@/shared/mixins/medical-report.mixin'
import ExamInformationMixin from '@/shared/mixins/exam-information.mixin'
import ExamUrgencyAlertMixin from '@/shared/mixins/exam-urgency-alert.mixin'
import CONSTANTS from '@/shared/constants/constants'
import ExamDetailsAlert from '@/components/exames/exams-details/exam-details-alert/index.vue'
import RadioExamsMixin from '@/shared/mixins/radio-report.mixin'
import RadioCategoryIcon from '@/shared/mixins/radio-category.mixin'

export default {
  name: 'radio-header',
  components: {
    RepetirExame,
    ExamDetailsAlert
  },
  mixins: [
    DateMixin,
    ExamInformationMixin,
    ExamUrgencyAlertMixin,
    RoleMixin,
    AuthMixin,
    MedicalReportMixin,
    RadioExamsMixin,
    RadioCategoryIcon
  ],
  props: {
    exam: {
      type: Object,
      default: () => ({}),
      required: true
    },
    hasCountdown: {
      type: Boolean,
      default: true
    },
    hasInformation: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      creationTime: Object,
      performLabel: '',
      categorySvg: '',
      informations: [],
      roles: [],
      instructionText: this.$i18n.t('add_here_questions_related'),
      isOit: false,
      isCategoryXray: false,
      isCanAlertUrgency: false,
      usePtmSigner: false,
      showTokenExpiredModal: false,
      key: 0
    }
  },
  computed: {
    currentUser() {
      const user = this.getUser()
      const currentRole = this.roles.find((role) => {
        return user.role.name === role.name
      })

      if (!currentRole) {
        return {}
      }

      return {
        name: user.fullname,
        role: currentRole.label
      }
    },
    formattedInformations() {
      return this.informations.map((information) => {
        const role = this.roles.find((role) => {
          return information.role === role.name
        })

        information.role = role.label
        information.creationDate = this.getFormattedDateHour(information.creationDate)
        return information
      })
    },
    hasTimelineAccess() {
      const user = this.getUser()
      return user.role.name === 'physician' && user.physician && !user.physician.onlyReports
    }
  },
  async mounted() {
    try {
      if (this.exam && this.exam.examId) {
        const { cert, token } = JSON.parse(sessionStorage.getItem('certificateValidation')) || {
          cert: false,
          token: false
        }

        const requests = [
          this.getRoles(),
          this.getInformationsByExamId(this.exam.examId),
          this.getCanAlertBySource(this.exam.examId)
        ]

        if (cert && token) {
          requests.push(this.getValidateCertificate())
        }

        const res = await Promise.all(requests)
        this.roles = res[0]
        this.informations = res[1].data
        this.isOit = this.exam.specialityId === CONSTANTS.SPECIALITY.RAIOXOIT
        this.isCategoryXray = this.exam.categorySpecialtyId === CONSTANTS.CATEGORYSPECIALTY.XRAY
        this.isCanAlertUrgency = res[2]

        if (cert && token && res.length > 3) {
          this.usePtmSigner = res[3].usePtmSigner
          if (res[3].tokenExpiration) {
            const date = new Date(res[3].tokenExpiration)
            this.tokenExpiration = isNaN(date.getTime()) ? res[3].tokenExpiration : date.toISOString()
          } else {
            this.tokenExpiration = ''
          }
        }
      }
    } finally {
      this.$root.$emit('hideLoading')
    }
  },

  methods: {
    async load() {
      this.creationTime = this.getDateDifferenceDays()
      this.performLabel = this.$i18n.tc(`time.${this.creationTime.label}`, this.creationTime.value)
      this.categorySvg = await this.loadCategorySvg(this.exam)
    },
    getDateDifferenceDays() {
      const initialDate = new Date(this.exam.creationDate)
      const today = new Date()
      const days = this.toDays(this.toUTC(today) - this.toUTC(initialDate))
      if (days) {
        return {
          value: days,
          label: 'day'
        }
      }

      const hours = today.getUTCHours() - initialDate.getUTCHours()
      if (hours) {
        return {
          value: hours,
          label: 'hour'
        }
      }

      const minutes = today.getUTCMinutes() - initialDate.getUTCMinutes()
      if (minutes) {
        return {
          value: minutes,
          label: 'minute'
        }
      }

      const seconds = today.getUTCSeconds() - initialDate.getUTCSeconds()
      return {
        value: seconds,
        label: 'second'
      }
    },
    toDays(date) {
      date = date || 0
      return date / 24 / 60 / 60 / 1000
    },
    toUTC(date) {
      if (!date || !date.getFullYear) {
        return 0
      }
      return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    },
    repeatExam(data) {
      this.$emit('repeatExam', data)
    },
    unassignExam(data) {
      this.$emit('unassignExam', data)
    },
    getClassButtons() {
      return {
        'repeat-exam-2-buttons': this.exam.pixeonUid
      }
    },
    hasSendInformation() {
      const user = this.getUser()
      return user.role.name === 'physician'
    },
    async clickSendInformation(information) {
      this.$root.$emit('showLoading')
      try {
        await this.sendInformation(this.exam.examId, information)

        this.$root.$emit('toast', {
          message: this.$i18n.t('radio_report.successfully_sent'),
          type: 'success'
        })

        if (this.$parent && typeof this.$parent.refreshAfterSendInformation === 'function') {
          this.$parent.refreshAfterSendInformation()
        }

        this.$refs.information.hide()
      } finally {
        this.$root.$emit('hideLoading')
      }
    },
    handleTokenExpired() {
      if (this.usePtmSigner) {
        sessionStorage.removeItem('certificateValidation')
        sessionStorage.removeItem('isValidatedPhysician')
        this.showTokenExpiredModal = true
      }
    },
    handleModalConfirm() {
      this.showTokenExpiredModal = false
      this.logoutUser()
    }
  }
}
