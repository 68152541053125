import DateMixin from '@/shared/mixins/date.mixin'
import ExamMixin from '@/shared/mixins/exam.mixin'
import CONSTANTS from '@/shared/constants/constants'

export default {
  name: 'exam-details-header',
  props: {
    details: {
      type: Object,
      required: true
    },
    statusIcon: {
      type: String,
      required: true
    }
  },
  mixins: [
    DateMixin,
    ExamMixin
  ],
  data() {
    return {
      editPatientName: false,
      editablePatientName: this.details.patientName,
      originalPatientName: this.details.patientName
    }
  },
  watch: {
    editablePatientName(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.editablePatientName = newVal.toUpperCase()
      }
    }
  },
  computed: {
    age () {
      return this.getDifferenceFromToday(this.details.patient.birthday, 'years')
    },
    gender () {
      const gender = this.details.patient.gender
      if (!gender) {
        return
      }

      if (gender.toLowerCase().startsWith('m')) {
        return this.$i18n.t('sex.male')
      }

      return this.$i18n.t('sex.female')
    }
  },
  methods: {
    yearMessage () {
      const verify = this.age >= 2 ? 2 : 1
      return this.$i18n.tc('time.year', verify, { amount: this.age })
    },
    enableEditName () {
      this.editPatientName = true
      this.editablePatientName = this.details.patientName
      this.originalPatientName = this.details.patientName
    },
    cancelEditName () {
      this.editPatientName = false
      this.editablePatientName = this.originalPatientName
    },
    async savePatientName () {
      if (this.editablePatientName !== this.originalPatientName) {
        try {
          const response = await this.updatePatientName(this.details.examId, this.editablePatientName)
          if (response.status === 200 || response.status === 202) {
            this.details.patientName = this.editablePatientName
            this.$root.$emit('toast', { message: this.$t('name_updated_success'), type: 'success' })
          } else {
            this.$root.$emit('toast', { message: this.$t('patient_name_update_error'), type: 'error' })
          }
        } catch (error) {
          console.error(this.$t('error_updating_patient_name'), error)
          this.$root.$emit('toast', { message: this.$t('server_communication_error'), type: 'error' })
        }
      }
      this.editPatientName = false
    },
    canEditPatientName () {
      const now = new Date()
      const creationDate = new Date(this.details.creationDate)
      const hoursDiff = (now - creationDate) / (1000 * 3600)

      const nonEditableStatuses = [
        CONSTANTS.STATUS.AGUARDANDO_LAUDO,
        CONSTANTS.STATUS.AGUARDANDO_INFORMACAO,
        CONSTANTS.STATUS.AGUARDANDO_OBSERVACAO,
        CONSTANTS.STATUS.LAUDO_LIBERADO
      ]

      const currentStatus = this.details.status.id || this.details.status 

      return hoursDiff <= 48 && !nonEditableStatuses.includes(currentStatus)
    }
  }
}