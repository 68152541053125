import ExamMixin from '@/shared/mixins/exam.mixin'

export default {
  name: 'examesPdf',
  props: {
    id: {
      type: String
    },
    isLaudo: {
      type: String,
      default: 'true'
    },
    pdfUrl: {
      type: String,
      default: ''
    }
  },
  mixins: [
    ExamMixin
  ],
  data () {
    return {
      url: '',
      msgError: ''
    }
  },
  async mounted () {
    try {
      if (this.pdfUrl) {
        this.url = this.pdfUrl
      } else {
        const paramId = JSON.parse(this.id)
        if (JSON.parse(this.isLaudo)) {
          if (Array.isArray(paramId)) {
            this.url = await this.printLaudo(paramId)
          } else {
            this.url = await this.showLaudo(paramId)
          }
        } else {
          this.url = await this.showExamFile(paramId)
        }
      }

      this.mountHtml()
    } catch (error) {
      this.$root.$emit('toast', { message: this.msgError, type: 'error' })
    }
  },
  created () {
    window.onbeforeunload = function () {
      localStorage.removeItem('print-laudos')
    }
  },
  methods: {
    showLaudo (paramId) {
      this.msgError = this.$i18n.t('error.an_error_occurred_retrieving_exam_report', { exam: paramId })
      return this.showExamLaudo(paramId)
    },
    printLaudo (paramId) {
      let ids = ''
      if (Array.isArray(paramId)) {
        ids = paramId.join(', ')
      } else {
        ids = paramId
      }
      this.msgError = this.$i18n.t('error.an_error_occurred_retrieving_exam_report_multiple', { exams: ids })
      return this.printExamLaudo(paramId)
    },
    showExamFile (paramId) {
      this.msgError = this.$i18n.t('error.an_error_occurred_retrieving_exam_file', { exam: paramId })
      return this.getExamFileById(paramId)
    },
    mountHtml () {
      document.body.innerHTML = `<object data="${this.url}" type='application/pdf' style="height: 100%; width: 100%;" />`
      window.history.pushState('pdf', '', this.url.substring(this.url.lastIndexOf('/')))
    }
  }
}
