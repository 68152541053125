import LocalStorageMixin from '@/shared/mixins/local-storage.mixin'
import Auth from '@/shared/mixins/auth.mixin'
import WebChatVollMixin from '@/shared/mixins/WebChatVoll.mixin'
import AuthService from '@/shared/services/auth.service'

export default {
  name: 'medico-validate',
  mixins: [
    LocalStorageMixin,
    Auth,
    WebChatVollMixin
  ],
  data: () => ({
    certValidate: {},
    urlParams: '',
    showDisable: false,
    AuthService: AuthService()
  }),
  created () {
    if (process.env.NODE_ENV !== 'testing') {
      this.startWebChatVoll()
    }
  },
  async mounted () {
    this.urlParams = (new URL(window.location)).searchParams
    if (this.urlParams.get('code')) {
      this.sendQrCode()
    }
    this.certValidate = await this.getValidateCertificate()
    sessionStorage.setItem('certificateValidation', JSON.stringify(this.certValidate))
    this.redirect()
  },
  computed: {
    casesOfError () {
      if (!this.certValidate.usePtmSigner) {
        return
      }
      if (!this.certValidate.notUsePtmSigner && !this.certValidate.cert) {
        return {
          message: 'Ops! Parece que todas suas fontes exigem certificado digital e seu certificado está com algum erro ou pode estar revogado. Caso deseje, entre em contato com o time operações pelo chat no canto inferior direito da tela.',
          action: ''
        }
      }
      if (!this.certValidate.notUsePtmSigner && !this.certValidate.token) {
        return {
          message: 'Ops! Parece que todas suas fontes exigem certificado digital e seu token precisa ser validado.',
          action: 'validateQRCodeButton'
        }
      }
      if (this.certValidate.notUsePtmSigner && !this.certValidate.cert) {
        return {
          message: 'Ops! Parece que algumas de suas fontes exigem certificado digital e seu certificado está com algum erro ou pode estar revogado. Caso deseje, entre em contato com o time operações pelo chat no canto inferior direito da tela.',
          action: 'disableSourceButton'
        }
      }
      if (this.certValidate.notUsePtmSigner && !this.certValidate.token) {
        return {
          message: 'Ops! Parece que algumas de suas fontes exigem certificado digital e seu token expirou. Você pode desabilitar as fontes temporariamente para laudar nas demais ou validar o seu QrCode.',
          action: 'multiplesOptions'
        }
      }
    }
  },
  methods: {
    redirect() {
      const { usePtmSigner, cert, token, notUsePtmSigner } = this.certValidate
      const listExam = this.AuthService.getListExam()
      let redirectPath

      if (listExam) {
        redirectPath = '/laudar-radio'
      } else if (this.AuthService.getOnlyReports()) {
        redirectPath = '/laudar'
      } else {
        redirectPath = '/'
      }

      if ((usePtmSigner && cert && token) || !usePtmSigner) {
        sessionStorage.setItem('isValidatedPhysician', 'true')
        this.$router.push(redirectPath)
      } else if (usePtmSigner && cert && !token && !notUsePtmSigner) {
        this.validateQRCode()
      }
    },



    validateQRCode () {
      window.location.href = this.certValidate.qrCodeUrl
    },

    disableSource() {
      const listExam = this.AuthService.getListExam()
      this.disablePhysicianSource()
      sessionStorage.setItem('isValidatedPhysician', 'true')
      if (listExam) {
        this.$router.replace('/laudar-radio')
      } else {
        this.$router.replace('/laudar')
      }
    },
    isErrorLoggedDontMatchUserScannedQrCode (error) {
      return (
        error.response &&
        error.response.status === 400 &&
        Array.isArray(error.response.data) &&
        error.response.data.length > 0 &&
        error.response.data[0].code === 'USER_LOGGED_DONT_MATCH_WITH_USER_SCANNED_QRCODE'
      )
    },
    async sendQrCode () {
      if (this.urlParams.get('code')) {
        try {
          await this.postSendQrCode(this.urlParams.get('code'))
        } catch (error) {
          if (this.isErrorLoggedDontMatchUserScannedQrCode(error)) {
            this.handleIncorrectUserError()
          } else {
            console.error('Erro ao enviar QR code:', error)
          }
        }
      }
    },
    handleIncorrectUserError () {
      this.casesOfError = {
        message: 'O usuário que escaneou o QR code não corresponde ao usuário logado. Por favor, tente novamente com o usuário correto.',
        action: 'validateQRCodeButton'
      }
    },
    logout () {
      this.logoutUser()
    }
  }
}
