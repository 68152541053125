import BaseMixin from './base.mixin'
import { store } from '@/store/store'

const mixin = {
  mixins: [
    BaseMixin
  ],
  methods: {
    async getSvgForLinkClientId(linkClientId) {
      try {
        const svgModule = await import(`../../assets/svg/category/${linkClientId}.svg?raw`);
        return svgModule.default;
      } catch (error) {
        console.warn(`failed to load svg for ${linkClientId}, using default`)
      }

      const svgModule = await import(`../../assets/svg/category/default.svg?raw`);
      return svgModule.default;
    },
    async getIconByCategoryForMedicHeader(category) {
      try {
        const svg = await this.getSvgForLinkClientId(category.linkClientId); // Aguarde a resolução da promessa
        return `<div class='physician-header-category-icon'>${svg}</div>`;
      } catch (e) {
        return `<div class='physician-header-category-icon'>${this.default}</div>`;
      }
    },
    getCategory(specialtyId) {
      return new Promise(async (resolve) => {
        const url = '/core/ExamSource/Category'

        if (!specialtyId) {
          if (!store.state.categories || !store.state.categories.length) {
            const res = await this.requestGet(url)
            const categories = res.data
            store.dispatch('setCategories', categories)
            return resolve(categories)
          }

          return resolve(store.state.categories)
        }

        const res = await this.requestGet(`${url}/${specialtyId}`)
        let data
        if (res.data) {
          data = res.data
          if (!Array.isArray(data)) {
            data = [data]
          }
        }

        resolve(data)
      })
    }
  }
}

export default mixin
