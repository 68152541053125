import { minLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapGetters, mapActions } from 'vuex'
import VueLodash from 'vue-lodash'  // eslint-disable-line
import _ from 'lodash';

export default {
  name: 'report',
  mixins: [
    validationMixin
  ],
  props: {
    isReadOnly: {
      type: Boolean,
      default: false
    },
    reportText: {
      type: String,
      default: ''
    },
    examId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      description: '',
      messageMinLength: this.$i18n.tc('field.min_length', 20),
      maxLength: 10000
    }
  },
  computed: {
    ...mapGetters({
      clearReport: 'getClearReport'
    }),
    reportLabel () {
      return this.getReportLabel()
    }
  },
  watch: {
    clearReport (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.description = ''
      }
    },
    description () {
      this.changeDescription()
    }
  },
  validations: {
    description: {
      minLength: minLength(20)
    }
  },
  mounted () {
    const savedReports = JSON.parse(localStorage.getItem('savedReports')) || []
    const savedReport = savedReports.find(report => report.id === this.examId)
  
    if (savedReport) {
      this.description = savedReport.text
    } else {
      this.description = this.reportText
    }
  
    this.setReportLabel(this.$i18n.t('description.report'))
    this.$watch('description', () => {
      this.saveReportText(this.examId)
    })
  },
  
  methods: {
    ...mapGetters([
      'getReportLabel'
    ]),
    ...mapActions([
      'setReportLabel'
    ]),
    /* eslint-disable */
    changeDescription: _.debounce(function () {
      this.$v.$touch()

      if (!this.isReadOnly) {
        this.$emit('changeDescription', this.description)
      }
    }, 500),
    clear () {
      this.description = ''
    },
    getValidationClass () {
      const field = this.$v.description
      if (field) {
        return {
          'md-invalid': (field.$dirty && field.$invalid) || (this.description && !this.description.trim())
        }
      }
    },
    addText (text) {
      this.description = this.description || ''
      const newText = `${this.description}\n\n${text}`
      if (newText.length <= parseInt(this.maxLength)) {
        if (this.description) {
          this.description += '\n\n'
        }
        this.description += text
      }
    },
    saveReportText (examId) {
      const savedReports = JSON.parse(localStorage.getItem('savedReports')) || []
      const currentReportIndex = savedReports.findIndex(report => report.id === examId)
      const currentReport = {
        id: examId,
        text: this.description
      }
      if (currentReportIndex !== -1) {
        savedReports[currentReportIndex] = currentReport
      } else {
        savedReports.push(currentReport)
      }
      localStorage.setItem('savedReports', JSON.stringify(savedReports))
    }
  }
}
