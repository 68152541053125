import ExamSourceMixin from '@/shared/mixins/exam-source.mixin'
import DateMixin from '@/shared/mixins/date.mixin'

export default {
  name: 'finantial-alert',
  components: {},
  mixins: [
    ExamSourceMixin,
    DateMixin
  ],
  data () {
    return {
      showAlert: false,
      deadline: ''
    }
  },
  computed: {
    finantialAlertMessage () {
      const blockingDate = this.addDays(new Date(this.dueDate), 5).toDate()
      const formattedBlockingDate = this.getFormattedDate(blockingDate)
      let message = ''
      if (new Date() > blockingDate) {
        message = this.$t('finantialAlertOverdueMessage')
      } else {
        message = this.$t('finantialAlertMessage', { date: formattedBlockingDate })
      }

      return message
    }
  },
  mounted () {
    this.getAlerts().then(res => {
      if (res.data) {
        this.showAlert = res && res.data && res.data.dueDate
        this.dueDate = res.data.dueDate
      }
    })
  },
  beforeUnmount () {
    this.showAlert = false
    this.dueDate = ''
  },
  methods: {
    getAlerts () {
      return this.getFinantialAlert()
    }
  },
  watch: {}
}
