import SpecialtyMixin from '@/shared/mixins/specialty.mixin'
import AuthMixin from '@/shared/mixins/auth.mixin'
import RadioExamsMixin from '@/shared/mixins/radio-report.mixin'
import DateMixin from '@/shared/mixins/date.mixin'
import RadioHeader from '@/components/medico/radioHeader/index.vue'
import RepetirExame from '@/components/medico/repetir-exame/index.vue'
import Laudar from '@/components/medico/laudar-exame/index.vue'
import RadioReportPopUp from '@/components/radio-popup/index.vue'

export default {
  name: 'radioReport',
  mixins: [SpecialtyMixin, AuthMixin, RadioExamsMixin, DateMixin],
  components: {
    RadioHeader,
    RepetirExame,
    Laudar,
    RadioReportPopUp
  },
  data() {
    return {
      reRenderKey: 0,
      reportText: '',
      examSelected: null,
      items: [],
      filteredItems: [],
      query: '',
      paginatedItems: [],
      viewingObservation: false,
      currentReport: null,
      currentObservation: {},
      isLoading: false,
      interval: null,
      selectedMasks: [],
      isReadOnly: false,
      repeat: false,
      reportStatus: '',
      abnormal: null,
      description: '',
      pinnedExam: null,
      showDialog: 0,
      nextExamId: null,
      isMounted: false,
      hasLoaded: false,
      lastUpdated: new Date(),
      isTimerActive: false,
    }
  },
  async mounted() {
    await this.load()
    this.isMounted = true
  },
  watch: {
    items: 'filterItems',
    query: 'filterItems',
    examSelected(newExamId) {
      this.$nextTick(() => {
        if (this.$refs.table) {
          this.$refs.table.sortBy = 'patientName'
          this.$refs.table.sortOrder = 'asc'
        }
      })
    },
    repeat(newValue) {
      if (newValue) {
        this.reportStatus = ''
      }
    },
  },
  computed: {
    sortedItems: {
      get() {
        return this.filteredItems
      },
      set(value) {
        this.filteredItems = value
      }
    },
    currentExam() {
      return (
        this.items.find((item) => item.examId === this.examSelected)
      )
    },
    formattedLastUpdated() {
      return this.$moment(this.lastUpdated).format('DD/MM/YYYY HH:mm:ss')
    }
  },
  methods: {
    clearForm() {
      this.reportStatus = ''
      this.repeat = false
      this.reportText = ''
      this.clearReportFromStorage(this.examSelected)
    },
    async handleForm() {
      try {
        if (this.repeat) {
          await this.examRepeatRadio(this.currentExam.examId, this.reportText)
        } else {
          await this.sendReportRadio(this.currentExam.examId, {
            reportText: this.reportText,
            abnormal: this.reportStatus === 'alterado'
          })
        }
        this.clearReportFromStorage(this.currentExam.examId)
        this.resetForm()

        await this.load()

        this.$nextTick(() => {
          if (this.sortedItems.length > 0) {
            this.selectExam(this.sortedItems[0].examId)
          }
        })

        this.showFeedback()
      } catch (error) {
        this.$root.$emit('toast', {
          message: this.$i18n.t('radio_report.error_sending_report'),
          type: 'error'
        })
      }
    },
    toggleReportStatus(status) {
      if (this.reportStatus === status) {
        this.reportStatus = ''
      } else {
        this.reportStatus = status
      }
      if (this.repeat) {
        this.repeat = false
      }
    },
    async load() {
      this.isLoading = true
      this.hasLoaded = false
      try {
        // await this.getValidateCertificate()
        this.items = await this.getRadioExamsList()
        this.lastUpdated = new Date()
        this.filterItems()
        this.reorderItems()
        this.hasLoaded = true

        if (this.items.length > 0) {
          this.selectExam(this.items[0].examId)
          this.$nextTick(() => {
            this.reRenderRadioHeader()
          })
          this.stopTimer()
        }else{
          this.isTimerActive = true
          this.startTimer()
        }
      } catch (error) {
        console.error('Erro ao carregar exames:', error)
      } finally {
        this.isLoading = false
      }
    },
    startTimer() {
      if (this.interval) return
      this.interval = setInterval(async () => {
        await this.load()
      }, 30000)
    },
    stopTimer() {
      clearInterval(this.interval)
      this.interval = null
    },
    resetRadioMaskButtons() {
      this.abnormal = null
      if (this.$refs.radioMask) {
        this.$refs.radioMask.resetButtons()
      }
    },
    handleRefreshConfirmation() {
      if (this.reportText.length > 0) {
        this.showDialog = 2
        return
      }
      return this.handleRefresh()
    },
    async handleRefresh() {
      try {
        this.items = await this.getRadioExamsList()
        this.lastUpdated = new Date()
        this.filterItems()
        this.reorderItems()

        if (this.sortedItems.length > 0) {
          this.selectExam(this.sortedItems[0].examId)
        }

        this.$root.$emit('toast', {
          message: this.$i18n.t('radio_report.exams_list_updated'),
          type: 'success'
        })
      } catch (error) {
        console.error('Erro ao atualizar a lista de exames:', error)
      }
    },
    async refreshAfterSendInformation() {
      await this.load()

      if (this.sortedItems.length > 0) {
        this.selectExam(this.sortedItems[0].examId)
      }
    },
    filterItems() {
      this.filteredItems = this.items
        .filter(
          (item) =>
            item.patientName.toLowerCase().includes(this.query.toLowerCase()) ||
            item.examId.toString().includes(this.query) ||
            item.specialtyName.toLowerCase().includes(this.query.toLowerCase())
        )
        .sort((a, b) => {
          if (a.urgent !== b.urgent) return a.urgent ? -1 : 1
          if (!!a.comment !== !!b.comment) return a.comment ? -1 : 1
          return 0
        })
        .map((item) => {
          item.truncatedPatientName = this.truncatePatientName(item.patientName)
          return item
        })
        if (!this.currentExam) {
          this.isTimerActive = true
          this.startTimer()
        }
    },
    handleRowSelected(examId) {
      if (this.examSelected === examId) {
        return
      }
      this.reRenderKey++
      if (this.reportText.trim().length > 0) {
        this.showDialog = 1
        this.nextExamId = examId
      } else {
        this.selectExam(examId)
      }
    },
    handleCloseComment() {
      this.viewingComment = false
    },
    handleCloseObservation() {
      this.viewingObservation = false
      this.$nextTick(() => {
        this.reorderItems()
      })
    },
    reorderItems() {
      this.filteredItems = this.filteredItems.sort((a, b) => {
        if (a.urgent !== b.urgent) return a.urgent ? -1 : 1
        if (!!a.comment !== !!b.comment) return a.comment ? -1 : 1
        return a.patientName.localeCompare(b.patientName)
      })
    },
    reRenderRadioHeader() {
      this.reRenderKey++
    },
    selectExam(examId) {
      this.pinnedExam = examId
      this.examSelected = examId
      this.resetRadioMaskButtons()
      this.resetForm()
    },
    resetForm() {
      this.reportStatus = ''
      this.repeat = false
      this.reportText = ''
      this.resetRadioMaskButtons()
    },
    handleRepeatChange() {
      if (this.repeat) {
        this.reportStatus = ''
      }
    },
    clearReportData() {
      this.description = ''
      if (this.$refs.report) {
        this.$refs.report.clear()
      }
    },
    clearReportFromStorage(examId) {
      const savedReports = JSON.parse(localStorage.getItem('savedReports')) || []
      const updatedReports = savedReports.filter((report) => report.id !== examId)
      localStorage.setItem('savedReports', JSON.stringify(updatedReports))
    },
    confirmChangeExam() {
      if (this.showDialog === 1) {
        this.clearForm()
        this.clearReportFromStorage(this.examSelected)
        this.selectExam(this.nextExamId)
        this.nextExamId = null
      } else {
        this.handleRefresh()
      }
      this.showDialog = 0
    },
    cancelChangeExam() {
      this.showDialog = 0
      this.nextExamId = null
    },
    async handleViewObservation(item) {
      this.examSelected = item.examId
      this.currentExam.notes = await this.getExamNotes(item.examId)
      this.viewingObservation = true
    },
    onChangeStatus(abnormal) {
      this.abnormal = abnormal
    },
    changeDescription(newDescription) {
      this.description = newDescription
    },
    showFeedback() {
      this.$root.$emit('toast', {
        message: this.$i18n.t('radio_report.successfully_sent'),
        type: 'success'
      })

      this.$nextTick(() => {
        if (this.sortedItems.length > 0) {
          this.selectExam(this.sortedItems[0].examId)
        }
      })

      this.examSelected = null
      this.description = ''
      this.abnormal = null
      this.isReadOnly = false

      if (this.$refs.report) {
        this.$refs.report.clear()
      }
    },
    truncatePatientName (name, maxLength = 23) {
      if (name.length <= maxLength) return name

      const nameParts = name.split(' ')
      let truncatedName = nameParts[0]

      for (let i = 1; i < nameParts.length; i++) {
        if ((truncatedName + ' ' + nameParts[i]).length > maxLength) {
          truncatedName += '...'
          break
        }
        truncatedName += ' ' + nameParts[i]
      }
      return truncatedName
    }
  },
  beforeDestroy() {
    this.stopTimer()
  }
}
