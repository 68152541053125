import UrgentMedicalReport from '@/components/exames/urgent-medical-report/index.vue'
import StatusMixin from '@/shared/mixins/status.mixin'
import AuthMixin from '@/shared/mixins/auth.mixin'

export default {
  name: 'exam-details-subheader',
  components: {
    UrgentMedicalReport
  },
  props: {
    details: {
      type: Object,
      required: true
    }
  },
  mixins: [
    StatusMixin,
    AuthMixin
  ],
  data () {
    return {
      urgent: false,
      showUrgent: false
    }
  },
  computed: {
    isEmergency () {
      return this.isEmergencySource()
    }
  },
  methods: {
    cancelUrgent () {
      this.urgent = false
      this.showUrgent = false
    },

    confirmUrgent (examId) {
      this.cancelUrgent()
      this.$emit('confirmUrgent', examId)
    }
  }
}
