import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import MessagesMixin from '@/shared/mixins/messages.mixin'
import FormValidationMixin from '@/shared/mixins/form-validation.mixin'
import ExamDetailsFile from '@/components/exames/exams-details/exam-detais-file/index.vue'
import MedicalReportMixin from '@/shared/mixins/medical-report.mixin'
import { mapGetters } from 'vuex'

export default {
  name: 'oit',
  components: {
    ExamDetailsFile
  },
  mixins: [
    validationMixin,
    MessagesMixin,
    FormValidationMixin,
    MedicalReportMixin
  ],
  props: {
    exam: {
      type: Object,
      default: {}
    },
    examId: {
      type: Number,
      default: ''
    }
  },
  data () {
    return {
      isLoading: false,
      files: [],
      pdfUrl: '',
      isPopUp: false,
      tooltips: {
        aa: this.$i18n.t('tooltips.aa'),
        at: this.$i18n.t('tooltips.at'),
        ax: this.$i18n.t('tooltips.ax'),
        bu: this.$i18n.t('tooltips.bu'),
        ca: this.$i18n.t('tooltips.ca'),
        cg: this.$i18n.t('tooltips.cg'),
        cn: this.$i18n.t('tooltips.cn'),
        co: this.$i18n.t('tooltips.co'),
        cp: this.$i18n.t('tooltips.cp'),
        cv: this.$i18n.t('tooltips.cv'),
        di: this.$i18n.t('tooltips.di'),
        ef: this.$i18n.t('tooltips.ef'),
        em: this.$i18n.t('tooltips.em'),
        es: this.$i18n.t('tooltips.es'),
        fr: this.$i18n.t('tooltips.fr'),
        hi: this.$i18n.t('tooltips.hi'),
        ho: this.$i18n.t('tooltips.ho'),
        id: this.$i18n.t('tooltips.id'),
        ih: this.$i18n.t('tooltips.ih'),
        kl: this.$i18n.t('tooltips.kl'),
        me: this.$i18n.t('tooltips.me'),
        od: this.$i18n.t('tooltips.od'),
        pa: this.$i18n.t('tooltips.pa'),
        pb: this.$i18n.t('tooltips.pb'),
        pi: this.$i18n.t('tooltips.pi'),
        px: this.$i18n.t('tooltips.px'),
        ra: this.$i18n.t('tooltips.ra'),
        rp: this.$i18n.t('tooltips.rp'),
        tb: this.$i18n.t('tooltips.tb')
      },
      report: null,
      Quality: {
        Number: null,
        Comment: ''
      },
      Radiography: {
        Normal: null,
        Comment: ''
      },
      Parenchyma: {
        Contains: null,
        SmallOpacities: {
          Primary: {
            P: false,
            Q: false,
            R: false,
            S: false,
            T: false,
            U: false
          },
          Secondary: {
            P: false,
            Q: false,
            R: false,
            S: false,
            T: false,
            U: false
          },
          Zones: {
            D1: false,
            D1Value: '',
            E1: false,
            E1Value: '',
            D2: false,
            D2Value: '',
            E2: false,
            E2Value: '',
            D3: false,
            D3Value: '',
            E3: false,
            E3Value: ''
          },
          Profusion: {
            P0_: false,
            P00: false,
            P01: false,
            P10: false,
            P11: false,
            P12: false,
            P21: false,
            P22: false,
            P23: false,
            P32: false,
            P33: false,
            P3_: false
          }
        },
        LargeOpacities: {
          Zero: false,
          A: false,
          B: false,
          C: false
        }
      },
      Pleural: {
        Contains: null,
        Plates: {
          Contains: null,
          WallProfile: {
            Local: {
              Zero: false,
              D: false,
              E: false
            },
            Classification: {
              Zero: false,
              D: false,
              E: false
            }
          },
          Front: {
            Local: {
              Zero: false,
              D: false,
              E: false
            },
            Classification: {
              Zero: false,
              D: false,
              E: false
            }
          },
          Diaphragm: {
            Local: {
              Zero: false,
              D: false,
              E: false
            },
            Classification: {
              Zero: false,
              D: false,
              E: false
            }
          },
          OtherLocations: {
            Local: {
              Zero: false,
              D: false,
              E: false
            },
            Classification: {
              Zero: false,
              D: false,
              E: false
            }
          }
        },
        WallExtension: {
          D: false,
          DZero: false,
          D1: false,
          D2: false,
          D3: false,
          DValue: '',
          E: false,
          EZero: false,
          E1: false,
          E2: false,
          E3: false,
          EValue: ''
        },
        Width: {
          D: false,
          Da: false,
          Db: false,
          Dc: false,
          DValue: '',
          E: false,
          Ea: false,
          Eb: false,
          Ec: false,
          EValue: ''
        }
      },
      OthersAbnormalities: {
        Contains: null,
        Symbols: {
          Co: false,
          Ra: false,
          Hi: false,
          Es: false,
          Cp: false,
          Cv: false,
          Id: false,
          Bu: false,
          Di: false,
          At: false,
          Ho: false,
          Cg: false,
          Tb: false,
          Rp: false,
          Kl: false,
          Px: false,
          Od: false,
          Me: false,
          Fr: false,
          Pi: false,
          Em: false,
          Ef: false,
          Ax: false,
          Ca: false,
          Cn: false,
          Ih: false,
          Pb: false,
          Pa: false,
          Aa: false
        },
        Comment: ''
      },
      SinusCostophrenicObliteration: {
        Contains: null,
        Zero: false,
        D: false,
        E: false
      },
      ThickeningPleuralDiffuse: {
        Contains: null,
        WallProfile: {
          Zero: false,
          Local: {
            Zero: false,
            D: false,
            E: false
          },
          Classification: {
            Zero: false,
            D: false,
            E: false
          }
        },
        Front: {
          Zero: false,
          Local: {
            Zero: false,
            D: false,
            E: false
          },
          Classification: {
            Zero: false,
            D: false,
            E: false
          }
        },
        WallExtension: {
          D: false,
          DZero: false,
          D1: false,
          D2: false,
          D3: false,
          DValue: '',
          E: false,
          EZero: false,
          E1: false,
          E2: false,
          E3: false,
          EValue: ''
        },
        Width: {
          D: false,
          Da: false,
          Db: false,
          Dc: false,
          DValue: '',
          E: false,
          Ea: false,
          Eb: false,
          Ec: false,
          EValue: ''
        }
      }
    }
  },
  validations: {
    Quality: {
      Number: {
        required
      }
    },
    Radiography: {
      Normal: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      clearReport: 'getClearReport'
    }),

    isNormal () {
      return this.Radiography.Normal === null || this.Radiography.Normal === true
    }
  },

  watch: {
    clearReport (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.report = null
        this.Quality = {
          Number: null,
          Comment: ''
        }
        this.Radiography = {
          Normal: null,
          Comment: ''
        }
        this.Parenchyma = {
          Contains: null,
          SmallOpacities: {
            Primary: {
              P: false,
              Q: false,
              R: false,
              S: false,
              T: false,
              U: false
            },
            Secondary: {
              P: false,
              Q: false,
              R: false,
              S: false,
              T: false,
              U: false
            },
            Zones: {
              D1: false,
              D1Value: '',
              E1: false,
              E1Value: '',
              D2: false,
              D2Value: '',
              E2: false,
              E2Value: '',
              D3: false,
              D3Value: '',
              E3: false,
              E3Value: ''
            },
            Profusion: {
              P0_: false,
              P00: false,
              P01: false,
              P10: false,
              P11: false,
              P12: false,
              P21: false,
              P22: false,
              P23: false,
              P32: false,
              P33: false,
              P3_: false
            }
          },
          LargeOpacities: {
            Zero: false,
            A: false,
            B: false,
            C: false
          }
        }
        this.Pleural = {
          Contains: null,
          Plates: {
            Contains: null,
            WallProfile: {
              Local: {
                Zero: false,
                D: false,
                E: false
              },
              Classification: {
                Zero: false,
                D: false,
                E: false
              }
            },
            Front: {
              Local: {
                Zero: false,
                D: false,
                E: false
              },
              Classification: {
                Zero: false,
                D: false,
                E: false
              }
            },
            Diaphragm: {
              Local: {
                Zero: false,
                D: false,
                E: false
              },
              Classification: {
                Zero: false,
                D: false,
                E: false
              }
            },
            OtherLocations: {
              Local: {
                Zero: false,
                D: false,
                E: false
              },
              Classification: {
                Zero: false,
                D: false,
                E: false
              }
            }
          },
          WallExtension: {
            D: false,
            DZero: false,
            D1: false,
            D2: false,
            D3: false,
            DValue: '',
            E: false,
            EZero: false,
            E1: false,
            E2: false,
            E3: false,
            EValue: ''
          },
          Width: {
            D: false,
            Da: false,
            Db: false,
            Dc: false,
            DValue: '',
            E: false,
            Ea: false,
            Eb: false,
            Ec: false,
            EValue: ''
          }
        }
        this.OthersAbnormalities = {
          Contains: null,
          Symbols: {
            Co: false,
            Ra: false,
            Hi: false,
            Es: false,
            Cp: false,
            Cv: false,
            Id: false,
            Bu: false,
            Di: false,
            At: false,
            Ho: false,
            Cg: false,
            Tb: false,
            Rp: false,
            Kl: false,
            Px: false,
            Od: false,
            Me: false,
            Fr: false,
            Pi: false,
            Em: false,
            Ef: false,
            Ax: false,
            Ca: false,
            Cn: false,
            Ih: false,
            Pb: false,
            Pa: false,
            Aa: false
          },
          Comment: ''
        }
        this.SinusCostophrenicObliteration = {
          Contains: null,
          Zero: false,
          D: false,
          E: false
        }
        this.ThickeningPleuralDiffuse = {
          Contains: null,
          WallProfile: {
            Zero: false,
            Local: {
              Zero: false,
              D: false,
              E: false
            },
            Classification: {
              Zero: false,
              D: false,
              E: false
            }
          },
          Front: {
            Zero: false,
            Local: {
              Zero: false,
              D: false,
              E: false
            },
            Classification: {
              Zero: false,
              D: false,
              E: false
            }
          },
          WallExtension: {
            D: false,
            DZero: false,
            D1: false,
            D2: false,
            D3: false,
            DValue: '',
            E: false,
            EZero: false,
            E1: false,
            E2: false,
            E3: false,
            EValue: ''
          },
          Width: {
            D: false,
            Da: false,
            Db: false,
            Dc: false,
            DValue: '',
            E: false,
            Ea: false,
            Eb: false,
            Ec: false,
            EValue: ''
          }
        }
      }
    },
    report: {
      handler () {
        this.saveStateToLocalStorage()
      },
      deep: true
    }
  },
  mounted () {
    try {
      const files = []
      const pdfFiles = this.exam.examFiles.filter((item) => {
        return item.extension.includes('pdf')
      })

      const mdtFiles = this.exam.examFiles.filter((item) => {
        return item.extension.includes('mdt')
      })

      const otherFiles = this.exam.examFiles.filter((item) => {
        return !item.extension.includes('pdf') && !item.extension.includes('mdt')
      })

      files.push(...pdfFiles)
      files.push(...mdtFiles)
      files.push(...otherFiles)

      this.files = files.map((item, index) => {
        item.title = `${this.$i18n.t('exam')} ${index + 1}`
        if (item.extension) {
          item.title += ` (${item.extension})`
        }
        return item
      })
      this.loadStateFromLocalStorage()
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    getPayLoad () {
      this.report = {
        id: this.examId,
        Quality: this.Quality,
        Radiography: this.Radiography,
        Parenchyma: this.Parenchyma,
        Pleural: this.Pleural,
        OthersAbnormalities: this.OthersAbnormalities,
        SinusCostophrenicObliteration: this.SinusCostophrenicObliteration,
        ThickeningPleuralDiffuse: this.ThickeningPleuralDiffuse
      }
      return this.report
    },
    saveStateToLocalStorage () {
      localStorage.setItem('savedOITReports', JSON.stringify(this.report))
    },
    loadStateFromLocalStorage () {
      const savedState = localStorage.getItem('savedOITReports')
      if (savedState) {
        const savedReport = JSON.parse(savedState)
        if (savedReport.id === this.examId) {
          this.report = savedReport
          this.Quality = this.report.Quality
          this.Radiography = this.report.Radiography
          this.Parenchyma = this.report.Parenchyma
          this.Pleural = this.report.Pleural
          this.OthersAbnormalities = this.report.OthersAbnormalities
          this.SinusCostophrenicObliteration = this.report.SinusCostophrenicObliteration
          this.ThickeningPleuralDiffuse = this.report.ThickeningPleuralDiffuse
        }
      }
    },
    enableReportButton () {
      if (this.Quality.Number !== null && this.Radiography.Normal !== null) {
        const oit = this.getPayLoad()
        this.$emit('enableReportButton', oit)
      }
    },
    getReport () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        return this.getPayLoad()
      }
    },
    async showFile (file) {
      try {
        this.$root.$emit('showLoading')
        const isPdf = file.extension === 'pdf'
        this.isPopUp = false
        this.pdfUrl = ''
        const url = await this.getExamFileDownloadSignedUrl(file.examFileId)
        if (isPdf) {
          window.open(url.data, '_blank')
        } else {
          this.isPopUp = true
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.href = url.data
          a.target = '_blank'
          a.download = this.$i18n.t('document')
          if (file.extension) {
            a.download += `.${file.extension}`
          }
          a.click()
        }
      } finally {
        this.$root.$emit('hideLoading')
      }
    }
  }
}
