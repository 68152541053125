import ExamMixin from '@/shared/mixins/exam.mixin'
import { mapActions } from 'vuex'
import Icon from '@/assets/svg/status/laudo_liberado.svg?raw'

export default {
  name: 'exam-details-1',
  props: {
    details: {
      type: Object,
      required: true
    }
  },
  mixins: [
    ExamMixin
  ],
  data () {
    return {
      placeholderSearch: this.$i18n.t('search_last_exams'),
      searchData: '',
      associationCandidates: [],
      hasAssociationCandidates: null,
      currentPage: 1,
      perPage: 5,
      icon: Icon,
      selectedExam: {}
    }
  },
  computed: {
    indexArrayStart () {
      return (this.currentPage - 1) * this.perPage
    },
    indexArrayEnd () {
      return this.indexArrayStart + this.perPage
    },
    filteredAssociationCandidates () {
      return this.filterBy(this.associationCandidates, this.searchData.trim(), 'patientName', 'examId')
    },
    associationCandidatesPaginated () {
      const slices = this.filteredAssociationCandidates.slice(this.indexArrayStart, this.indexArrayEnd)
      return slices
    },
    totalPages () {
      return Math.ceil(this.filteredAssociationCandidates.length / this.perPage)
    },
    navigationStatus () {
      const previousItem = this.indexArrayStart === 0
      const nextItem = this.indexArrayEnd >= this.filteredAssociationCandidates.length

      return { previousItem, nextItem }

      // se estiver na primeira pagina o botão de voltar fica inativo
      // se tiver apenas igual ou menos que cinco itens no Array, inativa os dois
      // se estiver na ultima página inativa o botão de ir
    },
    examIsNotSelected () {
      return Object.keys(this.selectedExam).length === 0
    }
  },
  async mounted () {
    this.associationCandidates = await this.getAssociationCandidates(this.details.examId)
    this.hasAssociationCandidates = this.associationCandidates.length > 0
  },
  methods: {
    ...mapActions(['changeUpdateGridExams', 'changeSidebarStatus']),
    searchError (errorMessage) {
      if (errorMessage) {
        this.$root.$emit('toast', { message: errorMessage, type: 'info' })
      }
    },
    changePaginate (type) {
      return type === 'previous' ? this.currentPage-- : this.currentPage++
    },
    selectExam (candidate) {
      this.selectedExam = candidate
    },
    isSelected (candidate) {
      return candidate.examId === this.selectedExam.examId ? 'exam-associate-list-item-active' : ''
    },
    resetSearch () {
      this.currentPage = 1
    },
    closeSidebar () {
      this.changeSidebarStatus(true)
    },
    saveAssociateExam () {
      const data = {
        oldExamId: this.details.examId,
        newExamId: this.selectedExam.examId
      }

      const response = this.postAssociateExam(data)
      if (response) {
        this.$root.$emit('toast', { message: this.$i18n.t('feedback.success_associate_exam'), type: 'success' })
        setTimeout(() => {
          this.changeUpdateGridExams(true)
        }, 500)
      } else {
        this.$root.$emit('toast', { message: this.$i18n.t('error.error_associate_exam'), type: 'error' })
      }
    }
  }
}
