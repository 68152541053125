import MedicalReportMixin from '@/shared/mixins/medical-report.mixin'
import { mapGetters, mapActions } from 'vuex'
import Dialog from '@/components/dialog/index.vue'
import clearIcon from '@/assets/svg/clear.svg?raw'
import WarningIcon from '@/assets/svg/warning-action.svg?raw'

export default {
  name: 'laudar-exame',
  props: {
    isReadOnly: {
      type: Boolean,
      default: false
    },
    examId: {
      type: Number,
      required: true
    },
    isAria: {
      type: Boolean,
      default: false
    },
    isOit: {
      type: Boolean,
      default: false
    }
  },
  mixins: [
    MedicalReportMixin
  ],
  components: {
    Dialog
  },

  data () {
    return {
      isEnabled: false,
      laudo: {
        type: Object,
        default: {
          examId: 0,
          description: '',
          status: 0,
          masks: []
        }
      },
      repeat: {
        default: {
          examId: 0,
          description: '',
          reasonsId: []
        }
      },
      clear: clearIcon,
      icon: WarningIcon,
      openDialog: false

    }
  },
  methods: {
    ...mapGetters([
      'getIsLaudar'
    ]),
    ...mapActions([
      'changeClearReport'
    ]),

    validateLaudar () {
      if (this.isOit) {
        this.$emit('reloadReport')
      } else {
        this.laudar()
      }
    },
    validateRepeat () {
      if (this.isOit) {
        this.$emit('reloadReport')
      } else {
        this.repeatExam()
      }
    },
    async laudar () {
      try {
        this.$root.$emit('showLoading')
        const report = await this.sendReport(this.laudo)
        const data = {
          hasMoreExams: report.data.existExam,
          type: ''
        }
        localStorage.removeItem('savedReports')
        localStorage.removeItem('savedOITReports')
        this.$emit('reportOk', data)
      } finally {
        this.$root.$emit('hideLoading')
      }
    },
    async repeatExam () {
      try {
        this.$root.$emit('showLoading')
        const {examId, description, reasonsId} = this.repeat
        const repeatExam = await this.examRepeat(examId, description, reasonsId || [])
        const data = {
          hasMoreExams: repeatExam.data.existExam,
          type: ''
        }
        this.$emit('reportOk', data)
      } finally {
        this.$root.$emit('hideLoading')
      }
    },
    isButtonEnabled () {
      if (!this.laudo || (this.laudo && this.laudo.description && (
        this.laudo.description.length < 20 ||
        this.laudo.description.length > 10000 ||
        !this.laudo.description.trim()))) {
        return false
      }
      return this.laudo.description && this.laudo.status
    },
    setLaudo (description, status, masksIds) {
      this.laudo = {
        examId: this.examId,
        description,
        status,
        masksIds
      }
    },
    setRepeat (description, reasonsId) {
      this.repeat = {
        examId: this.examId,
        description,
        reasonsId
      }
    },
    rectify () {
      this.$emit('clickRectify')
    }
  },
  computed: {
    isLaudar () {
      return this.getIsLaudar()
    }
  }
}
