import MedicoHeader from '@/components/medico/medicoHeader/index.vue'
import Mascara from '@/components/medico/mascara/index.vue'
import Report from '@/components/medico/report/index.vue'
import Laudar from '@/components/medico/laudar-exame/index.vue'
import ExamObservation from '@/components/medico/exam-observation/index.vue'
import ExamRetification from '@/components/medico/exam-retification/index.vue'
import PdfViewer from '@/components/medico/pdf-viewer/index.vue'
import CONSTANTS from '@/shared/constants/constants'
import MedicalReportMixin from '@/shared/mixins/medical-report.mixin'

export default {
  name: 'medico',
  components: {
    MedicoHeader,
    Mascara,
    Report,
    Laudar,
    ExamObservation,
    ExamRetification,
    PdfViewer
  },
  props: {
    exam: {
      type: Object,
      default: {}
    }
  },
  mixins: [
    MedicalReportMixin
  ],
  data () {
    return {
      description: '',
      status: 0,
      examId: 0,
      observationOpened: true,
      retificationOpened: true,
      pdfUrl: '',
      isLoading: false,
      files: [],
      isPopUp: false,
      imgDownload: '/assets/img/medical-report.png',
      isReadOnly: false,
      STATUS: CONSTANTS.STATUS
    }
  },
  async mounted () {
    try {
      this.isLoading = true
      this.isReadOnly = this.exam.status === this.STATUS.AGUARDANDO_RETIFICACAO || this.exam.status === this.STATUS.RETIFICANDO
      if (this.isReadOnly) {
        const reportStatus = this.exam.isAlterado ? CONSTANTS.REPORT_STATUS.ALTERADO : CONSTANTS.REPORT_STATUS.NORMAL
        this.$refs.laudar.setLaudo(this.exam.description, reportStatus, this.exam.masks)

        if (this.exam.status !== 43) {
          this.$refs.laudar.repeatExam(this.exam.description, reportStatus, this.exam.masks)
        }
      }

      const files = []
      const pdfFiles = this.exam.examFiles.filter((item) => {
        return item.extension.includes('pdf')
      })

      const mdtFiles = this.exam.examFiles.filter((item) => {
        return item.extension.includes('mdt')
      })

      const otherFiles = this.exam.examFiles.filter((item) => {
        return !item.extension.includes('pdf') && !item.extension.includes('mdt')
      })

      files.push(...pdfFiles)
      files.push(...mdtFiles)
      files.push(...otherFiles)

      this.files = files.map((item, index) => {
        item.title = `${this.$i18n.t('exam').toUpperCase()} ${index + 1}`
        if (item.extension) {
          item.title += ` (${item.extension})`
        }
        return item
      })

      if (pdfFiles.length) {
        this.pdfUrl = await this.getExamFileById(this.files[0].examFileId)
      } else {
        this.isPopUp = true
      }
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    changeStatus (status) {
      this.status = status
      this.validateLaudar()
    },
    changeDescription (description) {
      this.description = description
      this.validateLaudar()
      this.validateRepeat()
    },
    validateLaudar () {
      let masks = []
      if (this.$refs.mascara) {
        masks = this.$refs.mascara.getListIdUsedMask()
      }
      this.$refs.laudar.setLaudo(this.description, this.status, masks)
    },
    validateRepeat () {
      let reasons = []
      if (this.$refs.mascara) {
        reasons = this.$refs.mascara.getListIdUsedReason()
      }
      this.$refs.laudar.setRepeat(this.description, reasons)
    },
    showFeedback (hasMoreExams) {
      this.$refs.medicoHeader.stopCountDown()
      this.$emit('showFeedback', hasMoreExams)
    },
    openCard (value) {
      if (value === 'observations') {
        this.observationOpened = true
      } else {
        this.retificationOpened = true
      }
    },
    closeCard (value) {
      if (value === 'observations') {
        this.observationOpened = false
      } else {
        this.retificationOpened = false
      }
    },
    addMask (maskText) {
      this.$refs.report.addText(maskText)
    },
    removeMask () {
      this.validateLaudar()
    },
    addReason (reasonText) {
      this.$refs.report.addText(reasonText)
    },
    removeReason () {
      this.validateRepeat()
    },
    async showFile (file) {
      try {
        this.$root.$emit('showLoading')
        const isPdf = file.extension === 'pdf'
        const type = isPdf ? 'application/pdf' : 'octet/stream'
        this.isPopUp = false
        this.pdfUrl = ''
        const url = await this.getExamFileById(file.examFileId, type)
        if (isPdf) {
          this.pdfUrl = url
          return
        }
        this.isPopUp = true
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = 'documento'
        if (file.extension) {
          a.download += `.${file.extension}`
        }
        a.click()
      } finally {
        this.$root.$emit('hideLoading')
      }
    },

    sendInformation (data) {
      this.$emit('showFeedback', data)
    },
    clickRectify () {
      this.isReadOnly = false
      this.$refs.report.clear()
      this.$refs.mascara.clear()
    },
    getClassCard (value) {
      const isClosed = value === 'observations' ? !this.observationOpened : !this.retificationOpened
      const isOpened = value === 'observations' ? this.observationOpened : this.retificationOpened

      return {
        'card-closed': isClosed,
        'card-opened': isOpened
      }
    }
  }
}
