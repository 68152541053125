import { store } from '@/store/store'
import BaseMixin from './base.mixin'

const mixin = {
  mixins: [
    BaseMixin
  ],
  methods: {
    getCurrentUserSources () {
      return new Promise(async (resolve) => {
        const res = await this.getClient().get(`/core/ExamSource/ExamSource/CurrentUserSources`)
        if (res.status === 200 && res.data) {
          return resolve(res.data.data)
        }

        return resolve()
      })
    },
    getExamSourceLogo () {
      return new Promise(async (resolve) => {
        if (!store.state.examSourceLogo) {
          const res = await this.getClient().get(`/core/ExamSource/ExamSource/Logo`, { responseType: 'blob', useSelectedSource: true }, true)
          if (res.status === 200 && res.data) {
            const examSourceLogo = window.URL.createObjectURL(new Blob([res.data], { type: 'jpeg' }))
            store.dispatch('setExamSourceLogo', examSourceLogo)
            return resolve(examSourceLogo)
          }

          return resolve()
        }

        return resolve(store.state.examSourceLogo)
      })
    },
    getFinantialAlert () {
      return new Promise(async (resolve) => {
        const res = await this.getClient().get(`/core/ExamSource/FinantialAlert`, { useSelectedSource: true }, true)
        if (res.status === 200 && res.data) {
          return resolve(res.data)
        }

        return resolve()
      })
    },
    addSubscription (subscription) {
      return this.requestPost('/core/ExamSource/AddSubscription', subscription)
    }
  }
}

export default mixin
