const mixin = {
  methods: {
    getValidationClass (obj, fieldName) {
      const separator = '.'
      const properties = Array.isArray(fieldName) ? fieldName : fieldName.split(separator)

      let field = obj[fieldName]
      field = Array.isArray(fieldName) ? fieldName : fieldName.split(separator)
      field = properties.reduce((prev, curr) => prev && prev[curr], obj)

      if (field) {
        return {
          'md-invalid': field.$dirty && field.$invalid
        }
      }
    },
    validateStep () {
      if (!this.$v) {
        return true
      }

      this.$v.$touch()
      return !this.$v.$invalid
    }
  }
}

export default mixin
