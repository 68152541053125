import axios from 'axios'
import i18n from '@/i18n'

const client = axios.create({
  baseURL: import.meta.env.VITE_URL_API,
  timeout: 60000,
  headers: {
    'Accept-Language': i18n.locale
  }
})

client.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem('user')) || null
  const token = user ? user.token : null
  const refreshToken = user ? user.refreshToken : null

  if (config.url.indexOf('Token') === -1 && token) {
    if (refreshToken) {
      config.headers.refreshToken = refreshToken
    }

    config.headers.Authorization = token
  }

  return config
})

client.interceptors.response.use((response) => {
  if (response.headers.authorization) {
    const user = JSON.parse(localStorage.getItem('user')) || null
    user.token = response.headers.authorization
    user.refreshToken = response.headers.refreshtoken

    localStorage.setItem('user', JSON.stringify(user))

    client.interceptors.request.use((config) => {
      config.headers.common.Authorization = response.headers.authorization
      config.headers.common.refreshToken = response.headers.refreshtoken

      return config
    })
  }

  return response
})

client.interceptors.request.use((config) => {
  if (config.useSelectedSource) {
    delete config.useSelectedSource
    const selectedSource = JSON.parse(localStorage.getItem('selectedSource'))
    if (selectedSource) {
      config.params = {...config.params, sourceId: selectedSource.id}
    }
  }

  return config
})

export async function requestGet (url, config = {}) {
  const res = await client.get(url, config)
  return res
}
