import preorderDetailsPreviewDocuments from '@/components/preorders/preorder-details/preorder-details-preview-documents/index.vue'
import preorderDetailsInformations from '@/components/preorders/preorder-details/preorder-details-informations/index.vue'
import preorderDetailsHeader from '@/components/preorders/preorder-details/preorder-details-header/index.vue'
import preorderDetailsFiles from '@/components/preorders/preorder-details/preorder-details-files/index.vue'

export default {
  props: {
    details: {
      type: Object
    }
  },
  components: {
    preorderDetailsPreviewDocuments,
    preorderDetailsHeader,
    preorderDetailsInformations,
    preorderDetailsFiles
  }
}
