import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import Toasted from 'vue-toasted'
import Vue2Filters from 'vue2-filters'
import VTooltip from 'v-tooltip'
import 'portal-components/dist/style.css'
import * as PortalComponents from 'portal-components'
import { store } from './store/store'
import VueSocketIO from 'vue-socket.io'
import i18n from './i18n'
import VueMask from 'v-mask'

Object.entries(PortalComponents).forEach(([componentName, component]) => {
  Vue.component(componentName, component);
});

Vue.use(VueMask)
Vue.use(VTooltip)
Vue.use(Toasted)
Vue.use(VueMaterial)
Vue.use(Vue2Filters)
Vue.use(PortalComponents)
Vue.use(new VueSocketIO({
  debug: false,
  connection: import.meta.env.VITE_APPLICATION_ROOT,
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_'
  },
  options: {
    path: import.meta.env.VITE_SOCKET_PATH,
    transports: ['websocket', 'polling']
  }
}))

Vue.config.productionTip = false

// eslint-disable-next-line no-unused-vars
const app = new Vue({
  i18n,
  el: '#app',
  store,
  router,
  render: h => h(App),
  sockets: {}
})
