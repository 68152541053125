/* eslint-disable */

const mixin = {
  methods: {
    startWebChatVoll() {
      var t = document.createElement("iframe"); t.name = "iframe-webchat"; t.id = "iframe-webchat";
      document.get_body; document.body.appendChild(t), t.setAttribute("src", "/static/webchat.html"),
        t.setAttribute("style", "position: fixed;right:0;height:85px; width:85px; margin: 10px 20px;transform: translateZ(0);z-index: 9998;bottom: 0;"),
        t.setAttribute("frameborder", 0)
    }
  }
}

export default mixin
