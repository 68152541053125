import BaseMixin from './base.mixin'

const mixin = {
  mixins: [
    BaseMixin
  ],
  methods: {
    async sendUrgencyAlert (payload) {
      const url = 'core/UrgencyAlert/Add'
      const res = await this.requestPost(url, payload)
      return res.data.data
    },
    sendPostIKnow (urgencyAlertID) {
      const url = 'core/UrgencyAlert/PostIKnow'
      return this.requestPut(url, { urgencyAlertID })
    },
    async getUrgencyAlertByExamId (examId) {
      const url = 'core/UrgencyAlert/GetUrgencyAlerts'
      const res = await this.requestGet(`${url}?examId=${examId}`)
      return res.data.data
    },
    async getAllNoCheckedAlerts() {
      const res = await this.getClient().get('core/UrgencyAlert/GetAllNoCheckedAlerts', { useSelectedSource: true }, true);
      if (res.status === 200 && res.data) {
        return res.data.data;
      }
      return [];
    },
    async getCanAlertBySource (examId) {
      const res = await this.requestGet(`core/UrgencyAlert/CanAlertBySource?examId=${examId}`)
      return res.data
    },
    async getExamAlertCounters (startDate = '', endDate = '') {
      const res = await this.getClient().get(`core/UrgencyAlert/GetAlertCounters?startDate=${startDate}&endDate=${endDate}`,
        {
          useSelectedSource: true
        }, true);
      if (res.status === 200 && res.data) {
        return res.data.data;
      }
      return [];
    }
  }
}

export default mixin
