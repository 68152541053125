import { store } from '@/store/store'
import BaseMixin from './base.mixin'

const mixin = {
  mixins: [
    BaseMixin
  ],
  data () {
    return {
      mapStatus: null,
      released: 10,
      pendingNote: 18,
      pendingReport: 22,
      awaitingInformation: 26,
      repeat: 95
    }
  },
  mounted () {
    this.mapStatus = new Map()

    this.mapStatus.set(this.pendingReport, {
      id: this.pendingReport,
      icon: 'access_time',
      name: this.$i18n.t('waiting_report')
    })

    this.mapStatus.set(this.awaitingInformation, {
      id: this.awaitingInformation,
      icon: 'access_time',
      name: this.$i18n.t('waiting_information')
    })

    this.mapStatus.set(this.released, {
      id: this.released,
      icon: 'check_circle',
      name: this.$i18n.t('view_report')
    })

    this.mapStatus.set(this.pendingNote, {
      id: this.pendingNote,
      icon: 'access_time',
      name: this.$i18n.t('waiting_observation')
    })

    this.mapStatus.set(this.repeat, {
      id: this.repeat,
      icon: 'access_time',
      name: this.$i18n.t('repeat')
    })
  },
  methods: {
    getStatus () {
      return new Promise(async (resolve) => {
        const url = '/core/ExamSource/Exams/Status'

        if (!store.state.status || !store.state.status.length) {
          const status = await this.requestGet(url)
          store.dispatch('setStatus', status)
          return resolve(status)
        }

        return resolve(store.state.status)
      })
    },
    getStatusById (id) {
      return this.mapStatus.get(id)
    },
    isPendingReport (statusId) {
      return statusId === this.pendingReport
    }
  }
}

export default mixin
