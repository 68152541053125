import BaseMixin from './base.mixin'

const mixin = {
  mixins: [
    BaseMixin
  ],
  methods: {
    getPreOrders (filters = null, counterFilter) {
      return new Promise(async (resolve) => {
        const url = '/ptmsync/PreOrder'
        if (!filters) {
          const res = await this.requestGet(url)
          return resolve(res)
        }

        let queryFilters = Object.keys(filters).map((key) => {
          return `${key}=${filters[key].value}`
        })

        queryFilters = queryFilters.join('&')

        if (counterFilter !== null) {
          queryFilters = `${queryFilters}&${counterFilter}`
        }

        const res = await this.requestGet(`${url}/?${queryFilters}`)
        resolve(res.data)
      })
    },
    async getActivationCode () {
      const apiSecret = await this.requestPost('ptmsync/ActivationCode/Create', {})
      return apiSecret.data.code
    },
    async getPreOrdersStatus (filters) {
      const url = '/ptmsync/PreOrder'
      const res = await this.requestGet(`${url}/?${filters}&PageSize=1`)
      return res.data.totalOrders
    },
    getGridStatus () {
      const gridStatus = this.requestGet('/ptmsync/PreOrder/Status')
      return gridStatus
    },
    getPreOrderDetails (id) {
      const preOrderDetails = this.requestGet(`/ptmsync/PreOrder/Details?preOrderId=${id}`)
      return preOrderDetails
    },
    getPreOrderFileById (fileId) {
      return this.requestGet(`/ptmsync/PreOrder/SignedUrl?fileId=${fileId}`)
    },
    async postPreOrder (preorder) {
      const res = await this.requestPut(`/ptmsync/PreOrder`, preorder)
      return res
    }
  }
}
export default mixin
