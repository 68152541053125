import DateMixin from '@/shared/mixins/date.mixin'
import AuthMixin from '@/shared/mixins/auth.mixin'
import ExamObservation from '@/shared/mixins/exam-observation.mixin'
import CONSTANTS from '../../../../shared/constants/constants'

export default {
  name: 'exam-details-observation',
  props: {
    details: {
      type: Object,
      required: true
    }
  },
  mixins: [
    DateMixin,
    AuthMixin,
    ExamObservation
  ],
  data () {
    return {
      newObservation: null,
      STATUS: CONSTANTS.STATUS,
      creationDate: null
    }
  },
  computed: {
    hasAddObservation () {
      if (!this.details) {
        return false
      }

      return !(this.details.observations.length || this.details.originalStatus.id === this.STATUS.LAUDO_LIBERADO || this.details.originalStatus.id === this.STATUS.LAUDANDO)
    },
    observation () {
      if (!this.details.observations.length) {
        return {}
      }

      const observation = this.details.observations[0]
      this.creationDate = this.getFormattedDateHourMinSec(observation.creationDate)
      return observation
    }
  },
  methods: {
    async addObservation () {
      const observation = {
        content: this.newObservation,
        examId: this.details.examId,
        creationDate: this.getToday(),
        nameUser: this.getFullName()
      }

      this.$root.$emit('showLoading')
      try {
        const result = await this.addExamObservation(observation)
        const statusId = result.data.data.status.id
        this.details.observations = [observation]
        this.$emit('addObservation', statusId)
      } finally {
        this.$root.$emit('hideLoading')
      }
    }
  }
}
