import ExamUrgencyAlertMixin from '@/shared/mixins/exam-urgency-alert.mixin'
import DateMixin from '@/shared/mixins/date.mixin'
import RoleMixin from '@/shared/mixins/role.mixin'
import AuthMixin from '@/shared/mixins/auth.mixin'
import ExamDetailsPopupNotification from '@/components/exames/exams-details/exam-details-popup-notification/index.vue'

export default {
  name: 'exam-notification-urgency-alert',
  props: {
    payload: {
      type: Object,
      required: true
    },
    length: {
      type: Number
    }
  },
  mixins: [
    DateMixin,
    ExamUrgencyAlertMixin,
    RoleMixin,
    AuthMixin
  ],
  components: {
    ExamDetailsPopupNotification
  },
  data () {
    return {
      tooltip: this.$i18n.t('tooltips.urgency_alert', {length: this.length})
    }
  },
  methods: {
    openDetails () {
      this.showPopupNotification = true
      this.$emit('showPopupNotification', this.payload)
    }
  }
}
