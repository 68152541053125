import ExamUrgencyAlertMixin from '@/shared/mixins/exam-urgency-alert.mixin'
import DateMixin from '@/shared/mixins/date.mixin'
import RoleMixin from '@/shared/mixins/role.mixin'
import AuthMixin from '@/shared/mixins/auth.mixin'
import AlertIcon from '@/assets/svg/alert.svg?raw'
import TriangleIcon from '@/assets/svg/triangle.svg?raw'

export default {
  name: 'exam-details-popup-notification',
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  mixins: [
    DateMixin,
    ExamUrgencyAlertMixin,
    RoleMixin,
    AuthMixin
  ],
  data () {
    return {
      image: AlertIcon,
      icon: TriangleIcon
    }
  },
  methods: {
    clickIknow () {
      this.$root.$emit('showLoading')
      this.sendPostIKnow(this.payload.urgencyID)
        .then(() => {
          this.$emit('closeSuccess')
        }).catch(() => {
          this.$emit('closeCancel')
        }).finally(() => {
          this.$root.$emit('hideLoading')
          this.$router.go()
        })
    },
    clickCancel () {
      this.$emit('closeCancel')
    }
  }
}
