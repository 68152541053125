import DateMixin from '@/shared/mixins/date.mixin'
import RoleMixin from '@/shared/mixins/role.mixin'
import AuthMixin from '@/shared/mixins/auth.mixin'

export default {
  name: 'exam-observation',
  props: {
    observations: {
      type: Array,
      default: () => []
    },
    position: {
      type: String,
      default: 'left'
    },
    isAria: {
      type: Boolean,
      default: false
    }
  },
  mixins: [
    DateMixin,
    RoleMixin,
    AuthMixin
  ],
  data () {
    return {
      opened: true,
      iconOpen: 'chevron_right',
      iconClose: 'chevron_left',
      roles: [],
      user: null
    }
  },
  computed: {
    getClassContent () {
      return {
        'top': this.position === 'top'
      }
    },
    getClassIcon () {
      return {
        'md-size-2x': !this.opened
      }
    },
    getClassSizeLayoutIcon () {
      return {
        'md-size-10': this.opened
      }
    },
    formattedObservations () {
      if (!this.roles.length) {
        return
      }

      return this.observations.map((item) => {
        item.creationDate = this.getFormattedDateHourMinSec(item.creationDate)
        item.text = item.content ? item.content : ''
        item.name = item.user.name ? item.user.name : ''

        if (item.user.role) {
          const role = this.roles.find((role) => {
            return role.name === item.user.role
          })

          item.user.role = role.label
          if (role.name === 'source' && this.user.role.name === 'physician') {
            item.user.role = this.$i18n.t('technician')
          }
        } else {
          item.user.role = ''
        }

        return item
      })
    }
  },
  async mounted () {
    this.roles = await this.getRoles()
    this.user = this.getUser()

    if (this.position === 'top') {
      this.iconOpen = 'expand_more'
      this.iconClose = 'expand_less'
    }
  },
  methods: {
    open () {
      this.opened = true
      this.$emit('open')
    },
    close () {
      this.opened = false
      this.$emit('close')
    }
  }
}
