export default Object.freeze({
  SPECIALITY: {
    ECG: 19,
    RAIOX: 25,
    RAIOXOIT: 53,
    EEG: 20,
    ESPIRO: 22,
    TOMOGRAFIA: 87,
    RESSONANCIA: 109,
    MAMOGRAFIA: 110,
    DENSITOMETRIA: 113,
    ACUIDADE: 16,
    MAPA: 24,
    ESFORCO_CARDIOPULMONAR: 99,
    TESTE_ERGOMETRICO: 75,
    RM_PUNHOESQUERDO: 160
  },
  STATUS: {
    CRIADO: 5,
    LAUDANDO: 9,
    LAUDO_LIBERADO: 10,
    AGUARDANDO_OBSERVACAO: 18,
    AGUARDANDO_LAUDO: 22,
    AGUARDANDO_INFORMACAO: 26,
    AGUARDANDO_RETIFICACAO: 41,
    RETIFICANDO: 43,
    REPETIR: 95,
    CANCELADO: 99,
    PENDENCIAS: 300
  },
  REPORT_STATUS: {
    NAO_SELECIONADO: 0,
    NORMAL: 1,
    ALTERADO: 2,
    REPETIR: 9
  },
  ROLES: {
    ANALYST: 'analyst',
    PHYSICIAN: 'physician',
    SOURCE: 'source'
  },
  TRANSLATION: {
    STATUS: {
      10: 'released_report',
      18: 'waiting_observation',
      22: 'waiting_report',
      26: 'waiting_information',
      95: 'repeat',
      300: 'pendencies'
    }
  },
  CATEGORYSPECIALTY: {
    XRAY: 1
  }
})
