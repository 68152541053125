import BMF from 'browser-md5-file';

const browserMd5 = new BMF();

const mixin = {
  methods: {
    async getProperties(file) {
      const filereader = new FileReader();
      const blob = file.slice(0, 4);
      filereader.readAsArrayBuffer(blob);
      const evt = await new Promise((resolve) => {
        filereader.onloadend = resolve;
      });

      if (evt.target.readyState === FileReader.DONE) {
        const uint = new Uint8Array(evt.target.result);
        const bytes = [];
        uint.forEach((byte) => {
          bytes.push(byte.toString(16));
        });
        const hex = bytes.join('').toUpperCase();

        const { md5 } = await new Promise((resolve, reject) => {
          browserMd5.md5(file, (err, md5) => {
            if (err) {
              reject(err);
            } else {
              resolve({ md5 });
            }
          });
        });

        const mimeType = getMimetype(hex);
        let extension = getExtension(hex);

        if (!extension) {
          const splitFileName = file.name.split('.');
          extension = splitFileName[splitFileName.length - 1];
        }

        return {
          filename: file.name,
          filetype: file.type,
          binaryFileType: mimeType,
          size: file.size,
          hex,
          md5,
          extension,
        };
      }

      function getMimetype(signature) {
        switch (signature) {
        case '89504E47':
          return 'image/png';
        case '47494638':
          return 'image/gif';
        case '25504446':
          return 'application/pdf';
        case 'FFD8FFDB':
        case 'FFD8FFE0':
        case 'FFD8FFE1':
          return 'image/jpeg';
        case '504B0304':
        case '504B34':
          return 'application/zip';
        case '4D454449':
        case '8D41A4A9':
          return 'application/octet-stream';
        default:
          return 'application/octet-stream';
        }
      }

      function getExtension(signature) {
        switch (signature) {
        case '89504E47':
          return 'png';
        case '47494638':
          return 'gif';
        case '25504446':
          return 'pdf';
        case 'FFD8FFDB':
        case 'FFD8FFE0':
        case 'FFD8FFE1':
          return 'jpeg';
        case '504B0304':
        case '504B34':
          return 'zip';
        case '4D454449':
        case '8D41A4A9':
          return 'mdt';
        case '3135301A':
          return 'xcm';
        default:
          return '';
        }
      }
    },
  },
};

export default mixin;
