export default {
  props: {
    show: Number,
    title: String,
    message: String
  },
  methods: {
    confirm() {
      localStorage.removeItem('savedReports')
      this.$emit('confirm')
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
