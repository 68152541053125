import BaseMixin from './base.mixin'

const mixin = {
  mixins: [
    BaseMixin
  ],
  methods: {
    analystHasMoreExams () {
      return this.requestGet('/core/Analyst/Exams/Queue/HasNext')
    }
  }
}

export default mixin
