import preordersMixin from '@/shared/mixins/preorders.mixin'
import PdfViewer from '../../../medico/pdf-viewer/index.vue'

export default {
  props: {
    details: {
      type: Object
    }
  },
  data () {
    return {
      pdfUrl: ''
    }
  },
  components: {
    PdfViewer
  },
  mixins: [preordersMixin],

  mounted () {
    this.showFile()
  },

  methods: {
    convertDate (date) {
      return new Date(date).toLocaleString()
    },

    async showFile () {
      try {
        const url = await this.getPreOrderFileById(this.details.files[0].fileId)
        this.pdfUrl = url.data
      } finally {
        this.$root.$emit('hideLoading')
      }
    }
  }
}
