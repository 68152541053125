import DateMixin from '@/shared/mixins/date.mixin'
import RoleMixin from '@/shared/mixins/role.mixin'

export default {
  name: 'exam-retification',
  props: {
    retification: {
      type: Object,
      default: {}
    },
    position: {
      type: String,
      default: 'left'
    },
    isAria: {
      type: Boolean,
      default: false
    }
  },
  mixins: [
    DateMixin,
    RoleMixin
  ],
  data () {
    return {
      opened: true,
      iconOpen: 'chevron_right',
      iconClose: 'chevron_left',
      roles: []
    }
  },
  computed: {
    getClassContent () {
      return {
        'scroll': this.content ? this.content.length > 280 : false,
        'top': this.position === 'top'
      }
    },
    getClassIcon () {
      return {
        'md-size-2x': !this.opened
      }
    },
    getClassSizeLayoutIcon () {
      return {
        'md-size-10': this.opened
      }
    },
    formattedRetification () {
      if (!this.roles.length) {
        return
      }

      this.retification.creationDate = this.getFormattedDateHourMinSec(this.retification.creationDate)
      this.retification.user.role = this.roles.find((role) => {
        return role.name === this.retification.user.role
      }).label

      return this.retification
    }
  },
  async mounted () {
    this.roles = await this.getRoles()

    if (this.position === 'top') {
      this.iconOpen = 'expand_more'
      this.iconClose = 'expand_less'
    }
  },
  methods: {
    open () {
      this.opened = true
      this.$emit('open')
    },
    close () {
      this.opened = false
      this.$emit('close')
    }
  }
}
