const mixin = {
  methods: {
    isRequiredField (field, article = 'o') {
      return `${article.toUpperCase()} ${field} é obrigatóri${article.toLowerCase()}`
    },
    isNotValid (field, article = 'o') {
      return `${article.toUpperCase()} ${field} não é válid${article.toLowerCase()}`
    },
    minimumLength (minimum) {
      const plural = minimum > 1 ? 's' : ''
      return `Insira pelo menos ${minimum} caractere${plural}`
    },
    maximumLength (maximum) {
      return `Insira no máximo ${maximum} caracteres`
    },
    canNotContain (field, charactere, article = 'o') {
      return `${article.toUpperCase()} ${field} não pode conter o caractere ${charactere}`
    },
    loginCharactersAllowed () {
      return 'Logins podem conter letras minúsculas(a-z), números(0-9), traços(-), sublinhado(_), e pontos(.)'
    }
  }
}

export default mixin
