class AuthService {
  static instance = null;

  static getInstance () {
    if (AuthService.instance == null) {
      AuthService.instance = new AuthService()
    }

    return AuthService.instance
  }

  constructor () {
    this.user = JSON.parse(localStorage.getItem('user')) || null
  }

  isAuthenticated () {
    return this.getUser() !== null
  }

  getFirstRole () {
    return this.user ? this.user.roles[0] : ''
  }

  getUser () {
    return this.user
  }

  isIntercomPermited () {
    const roleName = this.getFirstRole()
    return roleName === 'source' || roleName === 'physician'
  }

  getOnlyReports () {
    const roleName = this.getFirstRole()
    return roleName === 'physician' && this.getUser().physician.onlyReports
  }

  getListExam () {
    const roleName = this.getFirstRole()
    return roleName === 'physician' && this.getUser().physician.listExams
  }

}

export default () => {
  return AuthService.getInstance()
}
