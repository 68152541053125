import BaseMixin from './base.mixin'

const mixin = {
  mixins: [
    BaseMixin
  ],
  methods: {
    getMasks (specialty) {
      return new Promise(async (resolve) => {
        const res = await this.requestGet(`/core/Physician/Mask/${specialty}`)
        resolve(res.data)
      })
    },
    getReasons (speciality) {
      return new Promise(async (resolve) => {
        const url = `/core/Physician/Exams/ReasonsToRepeatExamBySpecialityId?specialityId=${speciality}`
        const res = await this.requestGet(url)
        resolve(res.data)
      })
    }
  }
}

export default mixin
