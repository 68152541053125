import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  strict: true,
  state: {
    currentPath: '',
    workTimes: [],
    rolesMessenger: [],
    rolesFilter: [],
    roleIcons: [],
    roles: [],
    identificationTypes: [],
    status: [],
    examCount: 0,
    examSourceLogo: '',
    specialties: [],
    isSingleSession: null,
    dontReload: false,
    description: '',
    reportLabel: '',
    isLaudar: true,
    tab: null,
    titleInterval: null,
    updateGridExams: false,
    sidebarStatus: false,
    updateGridPreorders: false,
    updateClearReport: false,
    timerHash: null
  },
  getters: {
    getCurrentTab: (state) => {
      return state.tab
    },
    getCurrentPath: (state) => {
      return state.currentPath
    },
    getIsSingleSession: (state) => {
      return state.isSingleSession
    },
    getTimerHash: (state) => {
      return state.timerHash
    },
    getDontReload: (state) => {
      return state.dontReload
    },
    getSocket: (state) => {
      return state.socket
    },
    getDescription: (state) => {
      return state.reportLabel
    },
    getReportLabel: (state) => {
      return state.reportLabel
    },
    getIsLaudar: (state) => {
      return state.isLaudar
    },
    getTitleInterval: (state) => {
      return state.titleInterval
    },
    getUpdateGridExams: (state) => {
      return state.updateGridExams
    },
    getSidebarStatus: (state) => {
      return state.sidebarStatus
    },
    getExamCount: (state) => {
      return state.examCount
    },
    getUpdateGridPreorders: (state) => {
      return state.updateGridPreorders
    },
    getClearReport: (state) => {
      return state.updateClearReport
    }
  },
  mutations: {
    setCurrentTab: (state, payload) => {
      state.tab = payload
    },
    setCategories: (state, categories) => {
      state.categories = categories
    },
    setCurrentPath: (state, payload) => {
      state.currentPath = payload
    },
    setWorkTimes: (state, workTimes) => {
      state.workTimes = workTimes
    },
    setRolesMessenger: (state, rolesMessenger) => {
      state.rolesMessenger = rolesMessenger
    },
    setRolesFilter: (state, rolesFilter) => {
      state.rolesFilter = rolesFilter
    },
    setRoles: (state, roles) => {
      state.roles = roles
    },
    setRoleIcon: (state, roleIcon) => {
      const index = state.roleIcons.findIndex((currentRole) => {
        return currentRole.name === roleIcon.name
      })

      if (index > -1) {
        return
      }

      state.roleIcons.push(roleIcon)
    },
    setIdentificationTypes: (state, identificationTypes) => {
      state.identificationTypes = identificationTypes
    },
    setStatus: (state, status) => {
      state.status = status
    },
    setExamSourceLogo: (state, examSourceLogo) => {
      state.examSourceLogo = examSourceLogo
    },
    setSpecialties: (state, specialties) => {
      state.specialties = specialties
    },
    setSingleSession: (state, isSingleSession) => {
      state.isSingleSession = isSingleSession
    },
    setDontReload: (state, dontReload) => {
      state.dontReload = dontReload
    },
    setSocket: (state, socket) => {
      state.socket = socket
    },
    setReportLabel: (state, payload) => {
      state.reportLabel = payload
    },
    setDescription: (state, payload) => {
      state.description = payload
    },
    setTimerHash: (state, hash) => {
      state.timerHash = hash
    },
    setIsLaudar: (state, payload) => {
      state.isLaudar = payload
    },
    setExamCount: (state, payload) => {
      state.examCount = payload
    },
    incrementExamCount: (state) => {
      state.examCount++
    },
    setTitleInterval: (state, status) => {
      const favicon = document.querySelector("link[rel~='icon']")
      if (status) {
        state.titleInterval = setInterval(() => {
          const text1 = '(1) Novo exame | Laudar'
          const text2 = 'Portal Telemedicina'
          document.title = document.title === text1 ? text2 : text1
          const value1 = '/static/favicon1-32x32.png'
          const value2 = '/static/favicon2-32x32.png'
          favicon.href = favicon.href.split('/').reverse()[0] === value1.split('/').reverse()[0] ? value2 : value1
        }, 1000)
      } else {
        state.titleInterval = clearInterval(state.titleInterval)
        document.title = 'Portal Telemedicina'
        favicon.href = '/static/favicon1-32x32.png'
      }
    },
    setUpdateGridExams (state, status) {
      state.updateGridExams = status
    },
    setSidebarStatus (state, status) {
      state.sidebarStatus = status
    },
    setUpdateGridPreorders (state, status) {
      state.updateGridPreorders = status
    },
    setClearReport (state) {
      state.updateClearReport = !state.updateClearReport
    }
  },
  actions: {
    setCurrentTab: (context, payload) => {
      context.commit('setCurrentTab', payload)
    },
    setSingleSession: (context, payload) => {
      context.commit('setSingleSession', payload)
    },
    setDontReload: (context, payload) => {
      context.commit('setDontReload', payload)
    },
    setCurrentPath: (context, payload) => {
      context.commit('setCurrentPath', payload)
    },
    setWorkTimes (context, workTimes) {
      context.commit('setWorkTimes', workTimes)
    },
    setRolesMessenger: (context, rolesMessenger) => {
      context.commit('setRolesMessenger', rolesMessenger)
    },
    setRolesFilter: (context, rolesFilter) => {
      context.commit('setRolesFilter', rolesFilter)
    },
    setRoles: (context, roles) => {
      context.commit('setRoles', roles)
    },
    setRoleIcon: (context, roleIcon) => {
      context.commit('setRoleIcon', roleIcon)
    },
    setIdentificationTypes: (context, identificationTypes) => {
      context.commit('setIdentificationTypes', identificationTypes)
    },
    setStatus: (context, status) => {
      context.commit('setStatus', status)
    },
    setExamSourceLogo: (context, examSourceLogo) => {
      context.commit('setExamSourceLogo', examSourceLogo)
    },
    setSpecialties: (context, specialties) => {
      context.commit('setSpecialties', specialties)
    },
    setCategories: (context, categories) => {
      context.commit('setCategories', categories)
    },
    setReportLabel: (context, payload) => {
      context.commit('setReportLabel', payload)
    },
    setDescription: (context, payload) => {
      context.commit('setDescription', payload)
    },
    setIsLaudar: (context, laudar) => {
      context.commit('setIsLaudar', laudar)
    },
    setTimerHash: (context, hash) => {
      context.commit('setTimerHash', hash)
    },
    changeTitleInterval: (context, status) => {
      context.commit('setTitleInterval', status)
    },
    changeUpdateGridExams: (context, status) => {
      context.commit('setUpdateGridExams', status)
    },
    changeSidebarStatus: (context, status) => {
      context.commit('setSidebarStatus', status)
    },
    setExamCount: (context, payload) => {
      context.commit('setExamCount', payload)
    },
    incrementExamCount: (context, status) => {
      context.commit('incrementExamCount')
    },
    changeUpdateGridPreorders: (context, status) => {
      context.commit('setUpdateGridPreorders', status)
    },
    changeClearReport: (context) => {
      context.commit('setClearReport')
    }
  }
})
