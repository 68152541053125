export default {
  name: 'pdf-viewer',
  props: {
    pdfUrl: {
      type: String,
      required: true,
      default: ''
    }
  },
  computed: {
    isMac(){
      const userAgent = navigator.userAgent
      return userAgent.includes("Mac")
    }
  },
  methods: {
    openInNewTab () {
      const route = this.$router.resolve({ name: 'examesPdf', query: { pdfUrl: this.pdfUrl } })
      window.open(route.href, '_blank')
    }
  }
}
