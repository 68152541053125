import moment from 'moment'
import MedicalReportMixin from '@/shared/mixins/medical-report.mixin'
import { mapGetters, mapActions } from 'vuex'
import IconEllipse from '@/assets/icons/ellipse.svg?raw'

export default {
  name: 'report-countdown',
  props: {
    examId: {
      type: Number,
      default: 0,
      medicalReportService: Object
    },
    prioritasUpdateTime: {
      type: String,
      default: ''
    },
    reportTimer: {
      type: Number,
      default: import.meta.env.VITE_MINUTES_TO_REPORT,
      medicalReportService: Object
    }
  },
  watch: {
    prioritasUpdateTime (newVal, oldVal) {
      this.restartCountDown()
    }
  },
  mixins: [
    MedicalReportMixin
  ],
  data () {
    return {
      isVisible: true,
      iconAlarm: '/assets/icons/alarm.svg',
      iconEllipse: IconEllipse,
      minutes: this.reportTimer,
      timeRemaining: '',
      interval: Object,
      lastVisibilityChangeTime: Date.now(),
      instanceHash: Math.random().toString(36).substring(7),
      showMiniCountdown: false,
      isTimeout: false,
      isShowingStillReportingPopup: false
    }
  },
  beforeDestroy () {
    this.stopCountDown()
  },
  mounted () {
    document.addEventListener('visibilitychange', () => this.handleVisibilityChange(this.instanceHash), false)
    this.setTimerHash(this.instanceHash)
    this.startCountDown()
    const observer = new IntersectionObserver((entries) => {
      if (!entries[0].isIntersecting) {
        this.showMiniCountdown = true
      } else {
        this.showMiniCountdown = false
      }
    })
    observer.observe(document.querySelector('#time-remaining'))
    this.$root.$on('refreshCountdownEvent', this.handleVisibilityChange)
  },
  destroyed () {
    document.removeEventListener('visibilitychange', () => this.handleVisibilityChange(this.instanceHash), false)
  },
  methods: {
    ...mapGetters([
      'getTimerHash'
    ]),
    ...mapActions([
      'setTimerHash'
    ]),
    refreshCountDown () {
      this.refreshTimeout()
    },
    getDiff () {
      const lastUpdateOrDefault = new Date(this.prioritasUpdateTime)
      const pTime = moment(lastUpdateOrDefault).add(this.minutes, 'm')
      const now = new Date()
      const diff = moment.duration(pTime.diff(now)).asMilliseconds()
      return diff
    },
    countDown () {
      let diff = this.getDiff()
      this.timeRemaining = moment(diff).format('mm:ss')
      if (diff <= 0) {
        diff = 0
        this.timeRemaining = moment(diff).format('mm:ss')
        this.stopCountDown()
        this.unassignExam(true)
        this.isTimeout = true
      } else if (diff <= 15000) {
        this.showFeedbackPopup()
      }
    },
    showFeedbackPopup () {
      if (!this.isShowingStillReportingPopup) {
        this.$root.$emit('showFeedbackPopup')
        this.isShowingStillReportingPopup = true
      }
    },
    continueReporting () {
      this.showDialog = false
      this.feedbackShown = false
      this.isShowingStillReportingPopup = false
      this.$root.$emit('refreshCountdownEvent')
    },
    startCountDown () {
      this.countDown()
      this.interval = setInterval(this.countDown, 1000)
    },
    stopCountDown () {
      clearInterval(this.interval)
    },
    getClassTimeout () {
      const minuteRemaining = moment(this.timeRemaining, 'mm:ss').isBefore(moment('01:00', 'mm:ss'))
      const thirtySecondsRemaining = moment(this.timeRemaining, 'mm:ss').isBefore(moment('00:30', 'mm:ss'))
      return {
        'timeout': minuteRemaining,
        'timeout-blink': thirtySecondsRemaining && !this.isTimeout
      }
    },
    async unassignExam (showFeedback) {
      this.$root.$emit('showLoading')
      try {
        const res = await this.hasMoreExams()
        const hasMoreExams = res.data
        if (showFeedback) {
          const data = {
            hasMoreExams,
            type: 'unassign'
          }
          this.$emit('unassignExam', data)
        }
      } finally {
        this.$root.$emit('hideLoading')
      }
    },
    async refreshTimeout () {
      this.$root.$emit('showLoading')

      try {
        const data = await this.refreshReportTime(this.examId)
        this.$root.$emit('refreshTimeout', data.data)
        this.restartCountDown()
      } finally {
        this.$root.$emit('hideLoading')
      }
    },
    restartCountDown () {
      this.stopCountDown()
      this.startCountDown()
      this.isShowingStillReportingPopup = false
    },
    getCountDownMillis () {
      const pTime = moment(new Date(this.prioritasUpdateTime)).add(this.reportTimer, 'm')
      const now = new Date()
      const diff = moment.duration(pTime.diff(now)).asMilliseconds()
      return diff
    },
    countDownHalfway () {
      const now = moment(new Date())
      const diffLastUpdate = moment.duration(now.diff(this.lastVisibilityChangeTime)).asSeconds()
      const halfTimer = 60 * this.reportTimer / 2
      return diffLastUpdate > halfTimer
    },
    canUpdateTimer () {
      const timeRemaining = this.getCountDownMillis()
      return this.countDownHalfway() && timeRemaining > 0
    },
    async handleVisibilityChange () {
      this.isVisible = !document.hidden
      const currentTimerHash = this.getTimerHash()
      if (this.isVisible && this.canUpdateTimer() && currentTimerHash === this.instanceHash) {
        this.lastVisibilityChangeTime = new Date()
        await this.refreshTimeout()
      }
    }
  }
}
