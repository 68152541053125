import SpecialtyMixin from '@/shared/mixins/specialty.mixin'
import PreOrder from '@/shared/mixins/preorders.mixin'
import { mapActions } from 'vuex'
import moment from 'moment-timezone'
moment.tz.setDefault('America/Sao_Paulo')
moment.locale('pt-BR')

export default {
  props: {
    details: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      birthDate: '',
      sex: [{ name: this.$i18n.t('sex.male'), value: 'M' }, { name: this.$i18n.t('sex.female'), value: 'F' }],
      specialties: [],
      errorMessage: null
    }
  },
  mixins: [
    SpecialtyMixin,
    PreOrder
  ],

  computed: {
    checkRequiredInputs () {
      const patientName = !this.details.patientName ? 'md-invalid' : undefined
      const birthDate = !this.birthDate ? 'md-invalid' : undefined
      const gender = !this.details.gender ? 'md-invalid' : undefined
      const speciality = !this.details.speciality ? 'md-invalid' : undefined
      return { patientName, birthDate, gender, speciality }
    },
    getAge () {
      if (this.birthDate) {
        if (this.birthDate.length === 10) {
          const today = new Date()
          const [day, month, year] = this.birthDate.split('/').map(Number)
          const birthDate = new Date(year, month - 1, day)

          let age = today.getFullYear() - birthDate.getFullYear()
          const monthDifference = today.getMonth() - birthDate.getMonth()
          const dayDifference = today.getDate() - birthDate.getDate()

          if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
            age--
          }
          return age
        }
      }
    },
    sidebarRules () {
      if (this.details.status === 3 || this.details.status === 4) {
        return false
      }
      return true
    }
  },
  async mounted () {
    this.birthDate = this.formattedDateBR()
    this.specialties = await this.getSpecialties()
  },
  methods: {
    ...mapActions(['changeUpdateGridPreorders']),

    formattedDateBR () {
      const birthDateIsNotNull = this.details.birthDate != null && this.details.birthDate !== ''
      if (birthDateIsNotNull) {
        const dataObj = new Date(this.details.birthDate)
        const day = dataObj.getDate().toString().padStart(2, '0')
        const month = (dataObj.getMonth() + 1).toString().padStart(2, '0')
        const year = dataObj.getFullYear().toString()

        return `${day}/${month}/${year}`
      }
    },
    formattedDateUSA (date) {
      if (date.length === 10) {
        const [day, month, year] = date.split('/')
        return `${year}-${month}-${day}T00:00:00`
      }
      return date
    },
    savePreorder () {
      const data = {
        preOrderId: this.details.preOrderId,
        sourceId: this.details.sourceId,
        patientName: this.details.patientName,
        gender: this.details.gender,
        birthDate: this.formattedDateUSA(this.birthDate)
      }

      const check = this.isFormDataComplete(data)

      if (check) {
        const response = this.postPreOrder(data)
        if (response) {
          this.$root.$emit('toast', { message: this.$i18n.t('preorders.feedback.success_saving_preorder'), type: 'success' })
          setTimeout(() => {
            this.changeUpdateGridPreorders(true)
          }, 500)
        } else {
          this.$root.$emit('toast', { message: this.$i18n.t('preorders.feedback.error_saving_preorder'), type: 'error' })
        }
      } else {
        this.$root.$emit('toast', { message: this.$i18n.t(`preorders.feedback.${this.errorMessage}`), type: 'error' })
      }
    },

    checkDate (date) {
      const today = moment()

      if (date.length === 10) {
        const [day, month, year] = date.split('/')
        const formattedDate = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD')

        if (
          formattedDate.isValid() &&
          formattedDate.isSameOrBefore(today)
        ) {
          return true
        }
      }

      return false
    },

    isFormDataComplete (data) {
      const isPatientValid = data.patientName != null && data.patientName.trim() !== ''
      const isBirthDateNotNull = data.birthDate != null && data.birthDate.trim() !== ''
      const isGenderNotNull = data.gender != null && data.gender !== ''
      const isBirthDateValid = this.checkDate(this.birthDate)

      this.errorMessage = !isBirthDateValid ? 'error_saving_date' : 'error_saving_preorder'
      return isPatientValid && isBirthDateNotNull && isGenderNotNull && isBirthDateValid
    }
  }
}
