export default {
  name: 'exam-details-repeat',
  props: {
    details: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      user: [],
      active: false
    }
  }
}
