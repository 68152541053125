import BillingMixin from '@/shared/mixins/billing.mixin'
import MonthlyReportIcon from '@/assets/svg/monthly-report.svg'

export default {
  name: 'billing',
  mixins: [
    BillingMixin
  ],
  data () {
    return {
      img: MonthlyReportIcon,
      selectedReference: null,
      references: [],
      haveReference: false
    }
  },
  mounted () {
    this.billingReferences()
  },
  methods: {
    async billingReferences () {
      this.references = (await this.getBillingList()).data.references
      this.references.length ? this.haveReference = true : this.haveReference = false
    },
    async billingStatement (reference) {
      if (reference) {
        const billingStatement = await this.getBillingStatement(reference)
        window.open(billingStatement.data.signedUrl, '_blank')
      }
    }
  }
}
