import BaseMixin from './base.mixin'

const mixin = {
  mixins: [
    BaseMixin
  ],
  methods: {
    getExamFileById (fileId, type = 'application/pdf') {
      return this.requestGetBlob(`/core/Administrator/Exams/File?examFileId=${fileId}`, type)
    },
    removeExamFile (fileId, examId) {
      const file = {
        fileId,
        examId
      }
      return this.requestDelete(`/core/Exams/RemoveExamFile`, file)
    },
    getSignedUrl (examId, md5, extension, fileType) {
      const url = '/core/Administrator/Exam/SignedUrl'
      return this.requestGet(`${url}?ExamId=${examId}&Md5=${md5}&Extension=${extension}&fileType=${fileType}`)
    },
    async uploadFile (file, signedUrl) {
      const type = file.type ? file.type : 'application/octet-stream'
      const config = {
        headers: {
          'Content-Type': type
        }
      }
      await this.requestPut(signedUrl, file, config)
    },
    SetSuccessUploadStatus (fileId) {
      const url = '/core/Administrator/Exams/SetSuccessUploadStatus'
      return this.requestPut(`${url}?examFileId=${fileId}`)
    }
  }
}

export default mixin
