import MedicoHeader from '@/components/medico/medicoHeader/index.vue'
import ExamObservation from '@/components/medico/exam-observation/index.vue'
import PdfViewer from '@/components/medico/pdf-viewer/index.vue'
import MedicalReportMixin from '@/shared/mixins/medical-report.mixin'
import FileInfoMixin from '@/shared/mixins/file-info.mixin'
import ExamFileMixin from '@/shared/mixins/exam-file.mixin'
import ExamMixin from '@/shared/mixins/exam.mixin'

export default {
  name: 'analyst',
  components: {
    MedicoHeader,
    ExamObservation,
    PdfViewer
  },
  props: {
    exam: {
      type: Object,
      default: {}
    }
  },
  mixins: [
    MedicalReportMixin,
    FileInfoMixin,
    ExamFileMixin,
    ExamMixin
  ],
  data () {
    return {
      observationOpened: true,
      pdfUrl: '',
      isLoading: false,
      files: [],
      isPopUp: false,
      imgDownload: '/assets/img/medical-report.png',
      analyzeFileList: [],
      attachment: '',
      hasCountdown: false
    }
  },
  async mounted () {
    try {
      this.isLoading = true

      const files = []
      const pdfFiles = this.exam.examFiles.filter((item) => {
        return item.extension.includes('pdf')
      })
      const mdtFiles = this.exam.examFiles.filter((item) => {
        return item.extension.includes('mdt')
      })
      const otherFiles = this.exam.examFiles.filter((item) => {
        return !item.extension.includes('pdf') && !item.extension.includes('mdt')
      })

      files.push(...pdfFiles)
      files.push(...mdtFiles)
      files.push(...otherFiles)

      this.files = files.map((item, index) => {
        item.title = `${this.$i18n.t('exam')} ${index + 1}`
        if (item.extension) {
          item.title += ` (${item.extension})`
        }
        return item
      })

      if (pdfFiles.length) {
        this.pdfUrl = await this.getExamFileById(this.files[0].examFileId)
      } else {
        this.isPopUp = true
      }
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    showFeedback (hasMoreExams) {
      this.$emit('showFeedback', hasMoreExams)
    },
    openCard (value) {
      if (value === 'observations') {
        this.observationOpened = true
      }
    },
    closeCard (value) {
      if (value === 'observations') {
        this.observationOpened = false
      }
    },
    async showFile (file) {
      try {
        this.$root.$emit('showLoading')
        const isPdf = file.extension === 'pdf'
        const type = isPdf ? 'application/pdf' : 'octet/stream'
        this.isPopUp = false
        this.pdfUrl = ''
        const url = await this.getExamFileById(file.examFileId, type)
        if (isPdf) {
          this.pdfUrl = url
          return
        }
        this.isPopUp = true
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = this.$i18n.t('document')
        if (file.extension) {
          a.download += `.${file.extension}`
        }
        a.click()
      } finally {
        this.$root.$emit('hideLoading')
      }
    },
    sendInformation (data) {
      this.$emit('showFeedback', data)
    },
    getClassCard (value) {
      const isClosed = value === 'observations' ? !this.observationOpened : !this.retificationOpened
      const isOpened = value === 'observations' ? this.observationOpened : this.retificationOpened

      return {
        'card-closed': isClosed,
        'card-opened': isOpened
      }
    },
    async onSelectAttachment () {
      try {
        if (this.attachment.target.files.length) {
          this.$root.$emit('showLoading')
          const file = this.attachment.target.files[0]
          const fileInfo = await this.getProperties(file)
          const signedFile = await this.getSignedUrl(this.exam.examId, fileInfo.md5, fileInfo.extension, fileInfo.binaryFileType)
          signedFile.extension = fileInfo.extension.toLocaleLowerCase()
          await this.uploadFile(file, signedFile.data.linkStorage)
          const newFile = {}
          const count = this.analyzeFileList.length + 1
          newFile.title = `${this.$i18n.t('analysis')} ${count}`
          if (signedFile.extension) {
            newFile.title += ` (${signedFile.extension})`
          }
          newFile.examFileId = signedFile.data.fileId
          newFile.extension = signedFile.extension
          this.analyzeFileList.push(newFile)
          this.attachment = ''

          await this.SetSuccessUploadStatus(signedFile.data.fileId)
        } else {
          this.attachment = ''
        }
      } catch (e) {
        this.$root.$emit('toast', { message: this.$i18n.t('error.fail_to_send_file'), type: 'error' })
      } finally {
        this.$root.$emit('hideLoading')
      }
    },
    async removeFile (file) {
      await this.removeExamFile(file.id, this.exam.examId)
      this.analyzeFileList = this.analyzeFileList.filter(item => {
        return item.examFileId !== file.examFileId
      })
    },
    async completeReview () {
      this.$root.$emit('showLoading')
      let hasMoreExams = false
      try {
        const examId = this.exam.examId
        const res = await this.sendReview(examId)
        hasMoreExams = res.data.data.hasMoreExams
        const type = hasMoreExams ? '' : 'dontHaveExams'
        this.showFeedback({hasMoreExams, type})
      } finally {
        this.$root.$emit('hideLoading')
      }
    },
    hasMoreExams () {
      return this.requestGet('/core/Analyst/Exams/Queue/HasNext')
    }
  }
}
