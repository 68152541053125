export default {
  props: {
    openDialog: {
      type: Boolean
    },

    icon: {
      type: String
    },

    title: {
      type: String
    },

    subTitle: {
      type: String
    }
  },

  data () {
    return {
      dialog: false
    }
  },

  watch: {
    openDialog (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.dialog = true
      }
    }
  },

  methods: {
    confirmAction () {
      this.$emit('confirmAction')
      this.dialog = false
    }
  }
}
