import preorderDetails from '@/components/preorders/preorder-details/index.vue'
import preorderFileError from '@/components/preorders/preorder-file-error/index.vue'
import WebChatVollMixin from '@/shared/mixins/WebChatVoll.mixin'
import PreOrder from '@/shared/mixins/preorders.mixin'
import pdfViewer from '@/components/medico/pdf-viewer/index.vue'
import Filter from '@/shared/filter/filter'
import SpecialtyMixin from '@/shared/mixins/specialty.mixin'
import { mapGetters, mapActions } from 'vuex'
import iconDownloadPtmsync from '@/assets/svg/download_ptmsync.svg?raw'
import activationCode from '@/assets/svg/activation_code.svg?raw'
import backIcon from '@/assets/svg/back_to_exams.svg?raw'
import copyIcon from '@/assets/svg/copy.svg?raw'
import doneIcon from '@/assets/svg/done.svg?raw'
import FaltamDadosIcon from '@/assets/svg/status/faltam_dados.svg?raw'
import ArquivosNaFilaIcon from '@/assets/svg/status/arquivos_na_fila.svg?raw'
import ArquivosComErroIcon from '@/assets/svg/status/arquivos_com_erro.svg?raw'
import HistoricoArquivosIcon from '@/assets/svg/status/historico_arquivos.svg?raw'

export default {
  data () {
    return {
      dataGrid: [],
      headers: [
        this.$i18n.t('preorders.headers.date'),
        this.$i18n.t('preorders.headers.patient_name'),
        this.$i18n.t('preorders.headers.source_name'),
        this.$i18n.t('preorders.headers.file_name'),
        this.$i18n.t('preorders.headers.specialty'),
        this.$i18n.t('preorders.headers.status')
      ],
      headersFilters: [
        'originalFilename',
        'patientName',
        'specialityId'
      ],
      specialties: [],
      total: 0,
      page: 1,
      pageSize: 10,
      preorderDetails: {},
      icon: backIcon,
      counters: [
        {
          id: 1,
          icon: FaltamDadosIcon,
          number: '...',
          name: this.$i18n.t('preorders.status.files_missing_data'),
          statusGroup: [],
          filter: ''
        },
        {
          id: 2,
          icon: ArquivosNaFilaIcon,
          number: '...',
          name: this.$i18n.t('preorders.status.files_synchronizing'),
          statusGroup: [],
          filter: ''
        },
        {
          id: 3,
          icon: ArquivosComErroIcon,
          number: '...',
          name: this.$i18n.t('preorders.status.files_with_error'),
          statusGroup: [],
          filter: ''
        },
        {
          id: 4,
          icon: HistoricoArquivosIcon,
          number: this.$i18n.t('preorders.status.files_history'),
          name: this.$i18n.t('preorders.status.files_finished'),
          statusGroup: [],
          filter: ''
        }
      ],
      componentName: '',
      gridStatus: [],
      activeFilters: {},
      filterCounterSelected: null,
      statusData: [],
      maxCharChip: 50,
      hasSearch: false,
      iconDownloadPtmsync,
      activationCode,
      downloadPtmsyncTooltip: this.$i18n.t('tooltips.download_ptmsync'),
      alertActivationCode: false,
      apiSecret: '',
      selectedIcon: 'copyIcon',
      copyTooltip: this.$i18n.t('tooltips.copy'),
      copiedTooltip: this.$i18n.t('tooltips.copied'),
      openTooltip: false
    }
  },
  mixins: [
    WebChatVollMixin,
    PreOrder,
    SpecialtyMixin
  ],
  computed: {
    ...mapGetters({
      getUpdateGridPreorders: 'getUpdateGridPreorders',
      getSidebarStatus: 'getSidebarStatus'
    }),
    preorders () {
      return this.dataGrid.map((item) => {
        return {
          creationDate: item.creationDate,
          patientName: item.patientName,
          sourceName: item.sourceName,
          originalFilename: item.files.length > 0 ? item.files[0].originalFilename : '',
          speciality: this.getSpecialtyName(item.speciality),
          status: item.status,
          orderId: item.preOrderId,
          class: item.status === 3 || item.status === 4 ? this.setClass(item.viewed) : {}
        }
      })
    },
    length () {
      return this.componentName === 'preorder-details' ? '-wide' : '-thin'
    },
    titles () {
      return [
        { title: 'PendingUpload', translation: this.$i18n.t('preorders.status.pending_upload') },
        { title: 'Received', translation: this.$i18n.t('preorders.status.received') },
        { title: 'FileError', translation: this.$i18n.t('preorders.status.file_error') },
        { title: 'ValidationError', translation: this.$i18n.t('preorders.status.validation_error') },
        { title: 'Validated', translation: this.$i18n.t('preorders.status.validated') },
        { title: 'PendingOpening', translation: this.$i18n.t('preorders.status.pending_opening') },
        { title: 'Success', translation: this.$i18n.t('preorders.status.success') },
        { title: 'Canceled', translation: this.$i18n.t('preorders.status.canceled') }

      ]
    },
    copyIcon () {
      return this.selectedIcon === 'copyIcon' ? copyIcon : doneIcon
    }
  },
  components: {
    preorderDetails,
    preorderFileError,
    pdfViewer
  },
  watch: {
    getUpdateGridPreorders (newValue) {
      if (newValue) {
        this.updateGridPreorders()
      }
    },
    getSidebarStatus (newValue) {
      if (newValue) {
        this.closeSidebar()
      }
    }
  },
  created () {
    this.$root.$emit('showLoading')
    this.activeFilters.OrderBy = { value: 'asc' }
    this.activeFilters.OrderColumn = { value: 'creationDate' }

    if (process.env.NODE_ENV !== 'testing') {
      this.startWebChatVoll()
    }
  },
  async mounted () {
    const gridStatusData = await this.getGridStatus()
    this.gridStatus = gridStatusData.data
    this.setStatusData()
    this.setInitialFilters(gridStatusData.data)
    await this.getCounters(gridStatusData.data)
    this.specialties = await this.getSpecialties()
  },
  methods: {
    ...mapActions(['changeUpdateGridPreorders', 'changeSidebarStatus']),

    setInitialFilters (status) {
      const desiredStatus = [1, 2, 3, 4]
      status = status.filter((obj) => desiredStatus.includes(obj.id))
      const statusIds = status.map((obj) => `StatusId=${obj.id}`)
      const queryString = statusIds.join('&')
      this.filterCounterSelected = queryString
    },

    downloadPtmsyncFile () {
      this.$root.$emit('showLoading')
      const fileUrl = 'https://storage.googleapis.com/ptmsync-releases-public-prod/LauncherSetup.msi'
      const link = document.createElement('a')
      link.href = fileUrl
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      this.$root.$emit('hideLoading')
      this.alertActivationCode = true
      this.setActivationCode()
    },

    async setActivationCode () {
      this.apiSecret = await this.getActivationCode()
    },

    copy () {
      navigator.clipboard.writeText(this.apiSecret)
      this.selectedIcon = 'doneIcon'
      this.openTooltip = true

      setTimeout(() => {
        this.selectedIcon = 'copyIcon'
        this.openTooltip = false
      }, 4000)
    },

    addFilter (filter) {
      this.$refs.chipsComponent.addChip(filter.keyLabel, filter.valueLabel, filter.key)
    },

    async reloadPreorders () {
      this.page = 1
      this.pageSize = 10
      await this.getCounters(this.gridStatus)
      return this.getPreOrdersData()
    },

    closeSidebar () {
      this.$refs.grid.hideDetails()
      this.changeSidebarStatus(false)
    },

    clearFilters () {
      this.activeFilters = {}
      this.setInitialFilters(this.gridStatus)
      this.reloadPreorders()
    },

    refreshFilters () {
      this.reloadPreorders()
    },

    deleteFilter (filter) {
      const checkFilter = this.headersFilters.some((headerFilter) => headerFilter === filter)

      if (checkFilter) {
        this.removeSelectedFilter(filter)
        return this.reloadPreorders()
      }
      this.setInitialFilters(this.gridStatus)
      return this.reloadPreorders()
    },

    removeSelectedFilter (key) {
      delete this.activeFilters[key]
    },

    setStatusData () {
      const statusList = this.gridStatus.map(status => {
        const title = this.titles.find(item => item.title === status.title)
        return title ? {...status, name: title.translation} : status
      })

      const filterStatus = statusList.filter((status) => status.title !== 'Validated' && status.title !== 'PendingOpening' && status.title !== 'CreateOrderError')
      this.statusData = filterStatus
    },

    // Contadores agrupados por ID's
    setCountersIds (statusTitles, statusData) {
      const counterIds = []
      statusTitles.forEach((title) => {
        const statusObject = statusData.find((status) => status.title === title)
        counterIds.push(statusObject.id)
      })
      return counterIds
    },
    async getCounters (gridStatus) {
      this.counters[0].statusGroup = this.setCountersIds(['ValidationError'], gridStatus)
      this.counters[1].statusGroup = this.setCountersIds(['PendingUpload', 'Received'], gridStatus)
      this.counters[2].statusGroup = this.setCountersIds(['FileError'], gridStatus)
      this.counters[3].statusGroup = this.setCountersIds(['Success', 'PendingOpening', 'Validated', 'Canceled'], gridStatus)

      for (const counter of this.counters) {
        const queryString = counter.statusGroup.map(id => `StatusId=${id}`).join('&')
        counter.filter = queryString
        if (counter.id !== 4) {
          counter.number = await this.getPreOrdersStatus(queryString)
        }
      }

      this.getPreOrdersData()
    },
    setFilterStatus (counter) {
      this.filterCounterSelected = counter.filter
      this.addFilter(new Filter('StatusId', counter.id, this.$i18n.t('header.status'), counter.name))
      this.reloadPreorders()
    },
    search (value) {
      this.activeFilters.originalFilename = { value }
      this.addFilter(new Filter('originalFilename', 1, this.$i18n.t('preorders.headers.file_name'), value))
      this.reloadPreorders()
    },
    searchError (errorMessage) {
      if (errorMessage) {
        this.$root.$emit('toast', { message: errorMessage, type: 'info' })
      }
    },
    async getPreOrdersData () {
      this.$root.$emit('showLoading')
      this.$refs.grid.hideDetails()

      try {
        this.dataGrid = []
        this.setParams()

        // Realiza request no back-end com filtros ativos.
        const items = await this.getPreOrders(this.activeFilters, this.filterCounterSelected)

        const page = items.page
        const pageSize = items.pageSize
        const totalOrders = items.totalOrders

        if (totalOrders / pageSize < page && page !== 0) {
          this.page--
          return this.getPreOrdersData()
        }

        this.dataGrid = items.data
        this.total = items.totalOrders
        this.$refs.search.clear()
        return true
      } catch (errors) {
        return false
      } finally {
        this.$root.$emit('hideLoading')
      }
    },
    setParams () {
      this.activeFilters.Page = { value: this.page - 1 }
      this.activeFilters.PageSize = { value: this.pageSize }
    },
    orderByDate (order) {
      this.activeFilters.OrderColumn = { value: 'creationDate' }
      this.activeFilters.OrderBy = { value: order }

      this.getPreOrdersData()
    },

    individualStatusFilter (status) {
      if (status.title === 'Success') {
        const statusGroup = this.setCountersIds(['Success', 'PendingOpening', 'Validated'], this.gridStatus)
        const queryString = statusGroup.map(id => `StatusId=${id}`).join('&')
        const newStatus = {id: status.id, name: status.name, filter: queryString}
        this.setFilterStatus(newStatus)
      } else {
        const newStatus = {id: status.id, name: status.name, filter: `&StatusId=${status.id}`}
        this.setFilterStatus(newStatus)
      }
    },

    changePagination (data) {
      if (this.pageSize !== parseInt(data.pageSize)) {
        this.page = 1
      } else {
        this.page = parseInt(data.currentPage)
      }
      this.pageSize = parseInt(data.pageSize)
      this.getPreOrdersData()
    },

    getHeader (index) {
      return this.headers[index]
    },

    convertDate (date) {
      const currentLanguage = this.$i18n.locale
      return currentLanguage === 'en-US' ? new Date(date).toLocaleString('en-US').split(',')[0].trim() : new Date(date).toLocaleString('pt-BR').slice(0, 10)
    },

    getStatusInfo (status) {
      return this.gridStatus.find(gridStatus => gridStatus.id === status)
    },

    colorStatusClass (status) {
      const statusInfo = this.getStatusInfo(status)

      switch (statusInfo.title) {
        case 'PendingUpload': return 'sync'
        case 'FileError': return 'file-error'
        case 'ValidationError': return 'validation-error'
        case 'Received': return 'validating'
        case 'Validated': case 'PendingOpening': case 'Success': return 'success'
        case 'Canceled' : return 'canceled'
        default: break
      }
    },

    getStatusName (status) {
      const statusInfo = this.getStatusInfo(status)
      const findName = this.titles.find(titleStatus => titleStatus.title === statusInfo.title)
      return findName.translation
    },

    getSpecialtyName (id) {
      const specialty = this.specialties.find((specialty) => specialty.id === id)
      return specialty ? specialty.name : null
    },

    setClass (value) {
      if (value) {
        return {
          viewed: true,
          'not-viewed': false
        }
      } else {
        return {
          viewed: false,
          'not-viewed': true
        }
      }
    },

    setFilterSpecialty (specialty) {
      this.activeFilters.specialityId = { value: specialty.id }
      this.addFilter(new Filter('specialityId', specialty.id, this.$i18n.t('speciality'), specialty.name))
      this.getPreOrdersData()
    },

    setFilterPatientName (patient) {
      this.activeFilters.patientName = { value: patient }
      this.addFilter(new Filter('patientName', patient, this.$i18n.t('preorders.headers.patient_name'), patient))
      this.getPreOrdersData()
    },

    async getDetail (index) {
      const item = this.dataGrid[index]
      const details = await this.getPreOrderDetails(item.preOrderId)
      this.setViewed(item.preOrderId)

      this.componentName = this.colorStatusClass(item.status) === 'file-error' ? 'preorder-file-error' : 'preorder-details'
      this.preorderDetails = details.data
    },

    setViewed (orderId) {
      const item = this.preorders.find((item) => {
        return orderId === item.orderId
      })
      item.class.viewed = true
      item.class['not-viewed'] = false
      this.$forceUpdate()
    },

    updateGridPreorders () {
      this.clearFilters()
      this.$refs.chipsComponent.removeAllChips()
      this.changeUpdateGridPreorders(false)
    },

    backToExams () {
      this.$router.push({ path: '/exames' })
    }
  }
}
