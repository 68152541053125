import MedicalReportMixin from '@/shared/mixins/medical-report.mixin'

export default {
  name: 'repetir-exame',
  props: {
    examId: {
      type: Number,
      default: 0
    }
  },
  mixins: [
    MedicalReportMixin
  ],
  data () {
    return {
      reason: '',
      title: this.$i18n.t('reason_to_repeat_exam'),
      textButton: this.$i18n.t('repeat_exam'),
      iconButton: 'settings_backup_restore'
    }
  },
  methods: {
    async confirm (reason) {
      this.reason = reason

      try {
        this.$root.$emit('showLoading')
        const repeatExam = await this.examRepeat(this.examId, this.reason, [])
        this.$refs.description.clear()
        const data = {
          hasMoreExams: repeatExam.data.existExam,
          type: ''
        }
        this.$emit('repeatExam', data)
      } finally {
        this.$root.$emit('hideLoading')
      }
    }
  }
}
