import AuthMixin from '@/shared/mixins/auth.mixin'
import DateMixin from '@/shared/mixins/date.mixin'
import { mapGetters, mapActions } from 'vuex'

const DANGER_SUPPORT = 'danger'
const WARNING_SUPPORT = 'warning'

export default {
  name: 'support',
  mixins: [
    AuthMixin,
    DateMixin
  ],
  data () {
    return {
      message: '',
      interval: null,
      showSupport: false,
      type: 'danger'
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
    this.$socket.disconnect()
  },
  mounted () {
    this.startSocket()
  },
  methods: {
    ...mapGetters([
      'getIsSingleSession',
      'getDontReload'
    ]),
    ...mapActions([
      'setDontReload',
      'setSingleSession',
      'incrementExamCount'
    ]),
    startSocket () {
      const user = this.getUser()
      this.sockets.subscribe('connect', () => {
        if (user) {
          this.$socket.emit('authentication', {token: user.token})
        } else {
          this.$socket.emit('authentication', {token: 'guest'})
        }
      })

      this.sockets.subscribe('authenticated', (authType) => {
        if (authType === 'user') {
          this.$socket.emit('updateuser', {
            logid: user.logid,
            url: window.location.pathname
          })
        }
      })

      this.sockets.subscribe('duplicated', () => {
        this.setSingleSession(false)
      })

      this.sockets.subscribe('newexam', (data) => {
        this.incrementExamCount()
      })

      this.sockets.subscribe('single', () => {
        this.setSingleSession(true)
      })
      this.sockets.subscribe('reload', () => {
        if (!this.getDontReload()) {
          location.reload()
        } else {
          this.setDontReload(false)
        }
      })
      this.sockets.subscribe('logout', () => {
        this.$socket.disconnect()
        this.logoutUser()
      })
      this.sockets.subscribe('disconnect', () => {
        this.setSingleSession(true)
      })
      this.sockets.subscribe('maintenance', (data) => {
        clearInterval(this.interval)
        const estimatedEnd = new Date(data.estimatedEnd)
        this.maintenance(estimatedEnd)
        this.type = DANGER_SUPPORT
        this.showSupport = true
        this.interval = setInterval(() => this.maintenance(estimatedEnd), 1000)
      })

      this.sockets.subscribe('maintenance-notification', (data) => {
        clearInterval(this.interval)
        this.type = WARNING_SUPPORT
        this.showSupport = true
        this.message = this.getSupportMessage(data.estimatedBegin, data.estimatedEnd)
      })

      this.sockets.subscribe('maintenance-end', (data) => {
        clearInterval(this.interval)
        this.showSupport = false
      })

      this.sockets.subscribe('urgency-alert', () => {
        this.$root.$emit('WebSocketUrgencyAlert')
      })
    },
    getSupportMessage (startDate, endDate) {
      let message = ''
      const name = this.getFullName()
      if (name) {
        message += this.$i18n.t('support.hello_you', { customer: name })
      }
      message += this.$i18n.t('support.in_order_to_improve_our_services')
      if (startDate) {
        const estimatedBegin = new Date(startDate)
        message += this.$i18n.t('support.maintenance_time_start', { time: this.getFormattedHourMinutes(estimatedBegin), day: this.getFormattedDate(startDate) })
      }
      const estimatedEnd = new Date(endDate)
      message += this.$i18n.t('support.maintenance_time_end', { time: this.getFormattedHourMinutes(estimatedEnd), day: this.getFormattedDate(endDate) })

      return message
    },
    maintenance (estimatedEnd) {
      if (estimatedEnd > new Date()) {
        this.message = this.getSupportMessage('', estimatedEnd)
      } else {
        this.message = this.$i18n.t('support.maintenance_in_progress')
      }
    }
  }
}
