import BaseMixin from './base.mixin'
import LocalStorageMixin from '@/shared/mixins/local-storage.mixin'

const mixin = {
  mixins: [
    BaseMixin,
    LocalStorageMixin
  ],
  methods: {
    setPrintedReports () {
      this.saveInLocalStorage('print-laudos', true)
    },
    getPrintLaudos () {
      return this.getFromLocalStorage('print-laudos')
    },
    hasPrintedReports () {
      return this.getPrintLaudos() !== null
    },
    unsetPrintedReports () {
      this.removeFromLocalStorage('print-laudos')
    },
    printExamLaudo (examsId) {
      const param = `examsId=${examsId.join('&examsId=')}`
      return this.requestGetBlob(`/core/ExamSource/Exams/Reports?${param}`)
    },
    showExamLaudo (exameId) {
      return this.requestGetBlob(`/core/ExamSource/Exams/${exameId}/Report`)
    },
    getExams (filters = null) {
      return new Promise(async (resolve) => {
        const url = '/core/ExamSource/Exams'
        if (!filters) {
          const res = await this.requestGet(url)
          return resolve(res)
        }

        let queryFilters = Object.keys(filters).map((key) => {
          return `${key}=${filters[key].value}`
        })

        queryFilters = queryFilters.join('&')

        const res = await this.requestGet(`${url}?${queryFilters}`, { useSelectedSource: true })
        resolve(res.data)
      })
    },
    getExamsByDocument (filters = null) {
      return new Promise(async (resolve) => {
        const url = '/core/ExamSource/ExamsByDocument'
        if (!filters) {
          const res = await this.requestGet(url)
          return resolve(res)
        }

        let queryFilters = Object.keys(filters).map((key) => {
          return `${key}=${filters[key].value}`
        })

        queryFilters = queryFilters.join('&')

        const res = await this.requestGet(`${url}?${queryFilters}`)
        resolve(res.data)
      })
    },
    // Assíncrono
    async getAssociationCandidates (examId) {
      const candidates = await this.requestGet(`core/Exam/GetAssociationCandidates/${examId}`)
      return candidates.data
    },
    async postAssociateExam (examId) {
      const examAssociate = await this.requestPost(`/core/Exam/Associate`, examId)
      return examAssociate
    },
    getExamDetails (examId) {
      return this.requestGet(`/core/Administrator/Exams/Details?examId=${examId}`)
    },
    updatePatientName (examId, name) {
      return this.requestPut(`/core/ExamSource/Exams/${examId}`, { name })
    },
    getExamFileById (fileId, type = 'application/pdf') {
      return this.requestGetBlob(`/core/Administrator/Exams/File?examFileId=${fileId}`, type)
    },
    async getExamStatusCounters (filters) {
      const url = '/core/Exams/CountersWithFilter'
      if (!filters) {
        return this.requestGet(url)
      }

      let queryFilters = Object.keys(filters).map((key) => {
        let value = filters[key].value
        if (Array.isArray(value)) {
          value = value.join(`&${key}=`)
        }

        return `${key}=${value}`
      })

      queryFilters = queryFilters.join('&')
      const res = await this.requestGet(`${url}?${queryFilters}`, { useSelectedSource: true })
      return res
    },
    sendReview (examId) {
      return this.requestPost(`/core/Exams/SendReview`, { examId })
    },
    async getSourceDetails () {
      const res = await this.requestGet(`/core/ExamSource/DisplayOptions`, { useSelectedSource: true })
      return res.data
    }
  }
}

export default mixin