import ExamDetailsFileUpload from '@/components/exames/exams-details/exam-detais-file/exam-details-file-upload/index.vue'
import ExamDetailsAssociate from '@/components/exames/exams-details/exam-detais-file/exam-details-associate/index.vue'

export default {
  name: 'exam-details-file',
  props: {
    details: {
      type: Object,
      required: true
    }
  },

  components: {
    ExamDetailsFileUpload,
    ExamDetailsAssociate
  },
  data () {
    return {
      componentName: 'ExamDetailsFileUpload',
      Files: this.$i18n.t('files'),
      Associate: this.$i18n.t('associate_exam')
    }
  },
  computed: {
    isRepeat () {
      return this.details.status.name === 'Repetir'
    }
  }
}
