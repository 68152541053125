import UrgentMedicalReportMixin from '@/shared/mixins/urgent-medical-report.mixin'

export default {
  name: 'urgent-medical-report',
  props: {
    examId: {
      type: Number,
      default: 0,
      required: true
    }
  },
  mixins: [
    UrgentMedicalReportMixin
  ],
  data () {
    return {
      confirmImg: '/assets/img/confirm.png'
    }
  },
  methods: {
    async confirm () {
      this.$root.$emit('showLoading')
      try {
        await this.setUrgent(this.examId)
        this.$emit('confirm', this.examId)
      } finally {
        this.$root.$emit('hideLoading')
      }
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
