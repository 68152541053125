import Icon from '@/assets/svg/status/arquivos_com_erro.svg?raw'
import ArrowIcon from '@/assets/svg/arrow.svg?raw'

export default {
  props: {
    details: {
      type: Object
    }
  },
  data () {
    return {
      icon: Icon,
      arrow: ArrowIcon,
      img: '/assets/img/preorder-file-error.png',
      showMessage: false
    }
  },
  computed: {
    reasonsForInvalidFiles () {
      return [
        this.$t('preorders.file_error_reasons.reason_one'),
        this.$t('preorders.file_error_reasons.reason_two'),
        this.$t('preorders.file_error_reasons.reason_three')
      ]
    }
  },
  methods: {
    changeShowMessage (status) {
      this.showMessage = status
    },
    convertDate (date) {
      const currentLanguage = this.$i18n.locale
      return currentLanguage === 'en-US' ? new Date(date).toLocaleString('en-US') : new Date(date).toLocaleString('pt-BR').slice(0, 10)
    }
  }
}
