import BaseMixin from './base.mixin'

const mixin = {
  mixins: [
    BaseMixin
  ],
  data () {
    return {
      day: 'day',
      today: 'today',
      yesterday: 'yesterday',
      week: 'week',
      month: 'month',
      lastmonth: 'lastmonth',
      custom: 'custom',
      all: 'all'
    }
  },
  methods: {
    getFormattedDate (value) {
      if (!value) {
        return ''
      }

      return this.$root.$moment(value).format('DD/MM/YYYY')
    },
    getAge(birthDate) {
      if (!birthDate) {
        return '';
      }

      const birthMoment = this.$root.$moment(birthDate);
      const age = this.getToday().diff(birthMoment, 'years');
      return age;
    },
    getFormattedDateHour (value) {
      if (!value) {
        return ''
      }

      return this.$root.$moment(value).format('DD/MM/YYYY HH:mm')
    },
    getFormattedHourMinutes (value) {
      if (!value) {
        return ''
      }

      return this.$root.$moment(value).format('HH:mm')
    },
    getFormattedDateHourMinSec (value) {
      if (!value) {
        return ''
      }

      return this.$root.$moment(value).format('DD/MM/YYYY HH:mm:ss')
    },
    getFormattedHour (value) {
      if (!value) {
        return ''
      }

      return this.$root.$moment(value).format('HH:mm:ss')
    },
    getAmericanDate (value) {
      if (!value) {
        return ''
      }

      return this.$root.$moment(value).format('YYYY-MM-DD')
    },
    subtractDays (value, days) {
      if (!value) {
        return ''
      }

      return this.$root.$moment(value).subtract(days, 'days')
    },
    addDays (value, days) {
      if (!value) {
        return ''
      }

      return this.$root.$moment(value).add(days, 'days')
    },
    subtractMonths (value, months) {
      if (!value) {
        return ''
      }

      return this.$root.$moment(value).subtract(months, 'months')
    },
    addMonths (value, months) {
      if (!value) {
        return ''
      }

      return this.$root.$moment(value).add(months, 'months')
    },
    getToday () {
      return this.$root.$moment()
    },
    getYesterday () {
      return this.getToday().subtract(1, 'days')
    },
    getStartWeek () {
      const today = this.getToday()
      const day = today.day()
      return today.subtract(day, 'days')
    },
    getStartMonth () {
      const today = this.getToday()
      return today.date(1)
    },
    getStartLastMonth () {
      const today = this.getStartMonth()
      return today.subtract(1, 'month')
    },
    getEndLastMonth () {
      const date = this.getStartMonth()
      return date.subtract(1, 'days')
    },
    startMonth (month, year) {
      const date = this.getStartMonth().month(month).year(year)
      return date
    },
    endMonth (month, year) {
      const start = this.startMonth(month, year)
      const date = this.subtractDays(this.addMonths(start, 1).date(1), 1)
      return date
    },
    getTextDate (date) {
      return this.$root.$moment(date, 'YYYY-MM-DD').locale(this.$i18n.locale).format('LL')
    },
    diffDays (date1, date2) {
      if (!date1 || !date2) {
        return 0
      }

      if (typeof date1 === 'string' || date1 instanceof String) {
        date1 = this.$root.$moment(date1)
      }

      if (typeof date2 === 'string' || date2 instanceof String) {
        date2 = this.$root.$moment(date2)
      }

      return date1.diff(date2, 'days')
    },
    getDifferenceFromToday (date, field) {
      if (typeof date === 'string' || date instanceof String) {
        date = this.$root.$moment(date)
      }

      return this.getToday().diff(date, field)
    },
    toISOAsLocalDate (jsDate) {
      return this.$root.$moment(jsDate).utc(true).toISOString()
    },
    isValid (date = '') {
      if (!date) {
        return false
      }
      return this.$root.$moment(date).isValid()
    }
  }
}

export default mixin
