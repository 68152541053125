import CONSTANTS from '@/shared/constants/constants'
import MaskMixin from '@/shared/mixins/mask.mixin'
import ExamInformationMixin from '@/shared/mixins/exam-information.mixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'mascara-component',
  mixins: [
    MaskMixin,
    ExamInformationMixin
  ],
  props: {
    specialty: {
      type: Number,
      default: 0
    },
    selectedMasks: {
      type: Array,
      default: () => []
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    reportStatus: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      statusOptions: [
        {
          id: CONSTANTS.REPORT_STATUS.NAO_SELECIONADO,
          description: this.$i18n.t('click_to_change'),
          status: '',
          icon: '',
          class: 'status-pending'
        },
        {
          id: CONSTANTS.REPORT_STATUS.NORMAL,
          description: this.$i18n.t('this_exam_is'),
          status: this.$i18n.t('status.normal'),
          icon: 'check_circle',
          class: 'status-approved-icon'
        },
        {
          id: CONSTANTS.REPORT_STATUS.ALTERADO,
          description: this.$i18n.t('this_exam_is'),
          status: this.$i18n.t('status.abnormal'),
          icon: 'error_outline',
          class: 'status-altered-icon'
        },
        {
          id: CONSTANTS.REPORT_STATUS.REPETIR,
          description: this.$i18n.t('click_to_change'),
          status: '',
          icon: '',
          class: 'status-pending'
        }
      ],
      selectedStatus: {},
      masks: [],
      usedMasks: [],
      isChanged: false,
      textButton: this.$i18n.t('repeat_exam'),
      iconButton: 'settings_backup_restore',
      reasons: [],
      usedReasons: []
    }
  },
  async mounted () {
    this.setIsLaudar(true)
    this.selectedStatus = this.statusOptions.find((status) => {
      return status.id === this.reportStatus
    })

    try {
      this.masks = await this.getMasks(this.specialty)
      this.reasons = await this.getReasons(this.specialty)

      this.isChanged = this.selectedStatus.id === CONSTANTS.REPORT_STATUS.ALTERADO

      this.reasons.map((item) => {
        item.displayDescription = `<b>${item.name}</b><br/><br/><span>${item.text}</span>`
        item.class = this.selectedMasks.includes(item.id) ? 'selected' : ''
        return item
      })

      this.masks.map((item) => {
        item.displayDescription = `<b>${item.name}</b><br/><br/><span>${item.text}</span>`
        item.class = this.selectedMasks.includes(item.id) ? 'selected' : ''
        return item
      })
    } catch (error) {
      this.$root.$emit('toast', { message: this.$i18n.t('cant_load_masks'), type: 'error' })
    }
  },

  computed: {
    isLaudar () {
      return this.getIsLaudar()
    },

    filteredMasks () {
      const masks = this.masks
      return masks.filter((mask) => mask.isChanged === this.isChanged)
    },

    clearReport () {
      return this.getClearReport()
    },

    showDescriptionsTemplates () {
      if (this.isLaudar) {
        return this.masks.length
      } else {
        return this.reasons.length
      }
    }
  },

  watch: {
    clearReport (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.usedMasks = []
        this.usedReasons = []

        this.masks = this.masks.map((mask) => {
          return { ...mask, class: '' }
        })
        this.reasons = this.reasons.map((reason) => {
          return { ...reason, class: '' }
        })
        this.$emit('removeMask')
        this.$emit('removeReason')
        this.selectedStatus = this.statusOptions.find((status) => {
          return status.id === CONSTANTS.REPORT_STATUS.NAO_SELECIONADO
        })
      }
    }
  },

  methods: {
    ...mapActions([
      'setReportLabel',
      'setIsLaudar'
    ]),
    ...mapGetters([
      'getIsLaudar',
      'getClearReport'
    ]),
    repeatExam (data) {
      this.$emit('repeatExam', data)
    },
    changeStatus () {
      const selectedStatusId = this.selectedStatus.id === CONSTANTS.REPORT_STATUS.NORMAL ? CONSTANTS.REPORT_STATUS.ALTERADO : CONSTANTS.REPORT_STATUS.NORMAL
      this.selectedStatus = this.statusOptions.find((status) => {
        return status.id === selectedStatusId
      })

      this.isChanged = this.selectedStatus.id === CONSTANTS.REPORT_STATUS.ALTERADO
      this.$emit('changeStatus', this.selectedStatus.id)
      this.setReportLabel(this.$i18n.t('description.report'))
      this.setIsLaudar(true)
    },
    changeRepeat () {
      this.clear()
      this.selectedStatus = this.statusOptions.find((status) => {
        return status.id === CONSTANTS.REPORT_STATUS.REPETIR
      })

      this.$emit('changeStatus', this.selectedStatus.id)
      this.setReportLabel(this.$i18n.t('description.repeat_reason'))
      this.setIsLaudar(false)
    },
    clear () {
      this.selectedStatus = this.statusOptions.find((status) => {
        return status.id === CONSTANTS.REPORT_STATUS.NAO_SELECIONADO
      })
      this.isChanged = false
      this.usedMasks = []
      this.selectedMasks.length = 0

      this.masks.map((item) => {
        item.class = ''
        return item
      })
      this.reasons.map((item) => {
        item.class = ''
        return item
      })
    },
    addMask (mask) {
      if (this.isReadOnly) {
        return
      }

      const index = this.masks.findIndex((item) => {
        return item.id === mask.id
      })

      if (!mask.class) {
        mask.class = 'selected'
        this.usedMasks.push(mask)
        this.$emit('addMask', mask.text)
      } else {
        mask.class = ''
        this.usedMasks = this.usedMasks.filter((item) => {
          return item.id !== mask.id
        })
        this.$emit('removeMask')
      }
      this.$set(this.masks, index, mask)
    },
    addReason (reason) {
      if (this.isReadOnly) {
        return
      }

      const index = this.reasons.findIndex((item) => {
        return item.id === reason.id
      })

      if (!reason.class) {
        reason.class = 'selected'
        this.usedReasons.push(reason)
        this.$emit('addReason', reason.text)
      } else {
        reason.class = ''
        this.usedReasons = this.usedReasons.filter((item) => {
          return item.id !== reason.id
        })
        this.$emit('removeReason')
      }
      this.$set(this.reasons, index, reason)
    },
    getListIdUsedMask () {
      return this.usedMasks.map((item) => {
        return item.id
      })
    },
    getListIdUsedReason () {
      return this.usedReasons.map((item) => {
        return item.id
      })
    }
  }
}
